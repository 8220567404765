/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-alert */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-implied-eval */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable no-eval */
/* eslint-disable radix */
/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import { z } from 'zod';
import { AxiosError } from 'axios';
import type {
  Checklist,
  Question,
  Answer,
  Order,
  Property,
  Product,
  Dataset,
  Item,
  ProductPropertyCustomValue,
} from '../model/model.js';
import {
  ChecklistPropertyType,
  QuestionPropertyType,
  AnswerPropertyType,
  OrderPropertyType,
  QuestionType,
  ProductPropertyType,
  UserPropertyType,
  ItemPropertyType,
  CustomArray,
} from '../model/model.js';
import { ActivityHelper, Backend, HTTPMethods } from '../Utils/http-common.js';
import { Renderer } from './render.js';
import SignaturePad from '../Utils/signature/signature_pad.js';
import { QuestionHTMLutils } from './questionHTML.js';
import { setData } from '../Utils/storage.js';
import { logEntry } from '../Utils/logger.js';
import removeNullsAndUndefined from '../Utils/commonUtils.js';
import { setShowDebugInfo } from '../Utils/localstorage/debug.js';
import {
  errorToast, infoToast, warningToast,
} from '../Utils/toast.js';
import { DateUtils } from '../../sharedutils/index.js';

interface SignaturePath {
  lx: number;
  ly: number;
  mx: number;
  my: number;
}
const maxImageSize = 1920;

// From https://stackoverflow.com/a/59096915 and modified to TypeScript and removed "tricks"
function isVisible(el: HTMLElement | null) {
  return el && el.getClientRects().length !== 0;
}

function openMenu() {
  const button = document.getElementById('offcanvasOpenBtn');
  if (window.offcanvas && isVisible(button)) {
    window.offcanvas.show();
  }
}

function closeMenu() {
  const button = document.getElementById('offcanvasOpenBtn');
  if (window.offcanvas && isVisible(button)) {
    window.offcanvas.hide();
  }
}

function zeroPatch(number: number): string {
  return number < 10 ? `0${number}` : number.toString();
}

function nowString(): string {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const second = now.getSeconds();
  return `${year}${zeroPatch(month)}${zeroPatch(day)}${zeroPatch(hour)}${zeroPatch(minute)}${zeroPatch(second)}`;
}

export class ChecklistUtils {
  public holdRendering = false;

  showErrorToast(message: string, duration?: number) {
    errorToast(message, true, duration);
  }

  showWarningToast(message: string, duration?: number) {
    warningToast(message, true, duration);
  }

  showInfoToast(message: string, duration?: number) {
    infoToast(message, true, duration);
  }

  comparePropertiesAreTheSame(properties: Property[], toCompare: Property[]) {
    if (properties.length !== toCompare.length) {
      return false;
    }
    for (let i = 0; i < properties.length; i += 1) {
      const property = properties[i];
      const other = toCompare.find((p) => p.id === property.id);
      if (!other) return false;
      if (other.type !== property.type) return false;
      if (other.value !== property.value) return false;
    }
    return true;
  }

  async updateAnswer(
    q: { dataset: Dataset; files?: FileList; parentElement?: HTMLElement },
    answer: string | HTMLCanvasElement | HTMLImageElement | number,
    qty?: number,
  ) {
    /*
    clearTimeout(OrderReloadtimer);
    OrderReloadtimer = setInterval(() => {
      if (new Date().getTime() - lastAnswerTime.getTime() < orderReloadWindow) {
        if (window.Data.order[window.activeOrder] && window.Data.order[window.activeOrder].id > 0) {
          window.utils.openOrder('', '', window.Data.order[window.activeOrder].id.toString(), true);
        }
      }
    }, orderReloadTime);

    lastAnswerTime = new Date();
    */
    console.log('updateAnswer was called');
    const Qutils = new QuestionHTMLutils();
    const utils = new ChecklistUtils();
    try {
      // wait 100ms to allow the browser to update the DOM
      await new Promise((resolve) => { setTimeout(resolve, 100); });

      if (answer instanceof HTMLCanvasElement) {
        answer.toBlob(async (blob) => {
          if (!blob) {
            return;
          } // if file is empty, skip it
          const file = new File([blob], '.jpg', { type: 'image/jpeg' }); // create a new file
          const list = new DataTransfer();
          list.items.add(file);
          q.files = list.files;
          await this.updateAnswer(
            q,
            (answer as HTMLCanvasElement).toDataURL('image/jpeg', 0.5),
          ); // update the answer
        }, 'image/jpeg'); // set the type of the file
        answer.style.display = 'none'; // hide the canvas
        return;
      }
      if (!(answer instanceof HTMLCanvasElement) && q.files) {
        await new Promise((resolve) => { setTimeout(resolve, 100); });
        const img = q.parentElement?.getElementsByTagName('img')[0];
        if (!img) {
          return;
        }
        const file = q.files[0] as File;
        const filedata = (await loadImage(file)) as string;
        img.src = filedata;
        answer = filedata;
      }

      const now = new Date(Date.now()) as unknown;

      // OnAnswer

      let matchingQuestions: Question[] = [];
      for (let o = 0; o < window.Data.order.length; o++) {
        const order = window.Data.order[o];
        if (order.id == parseInt(q.dataset.orderid)) {
          for (let i = 0; i < order.item.length; i++) {
            const item = order.item[i];
            if (item.id == parseInt(q.dataset.itemid)) {
              if (item.product) {
                for (let p = 0; p < item.product.length; p++) {
                  const product = item.product[p];
                  if (product.id == parseInt(q.dataset.productid)) {
                    if (product.checklist) {
                      for (let c = 0; c < product.checklist.length; c++) {
                        const checklist = product.checklist[c];
                        if (checklist.id == parseInt(q.dataset.checklistid)) {
                          if (checklist.question) {
                            for (let qIndex = 0; qIndex < checklist.question.length; qIndex++) {
                              const question = checklist.question[qIndex];
                              if (question.id == parseInt(q.dataset.questionid)) {
                                if (question._seqno == parseInt(q.dataset.seqno) || 0) {
                                  matchingQuestions.push(question);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (matchingQuestions.length != 1
      ) {
        document.getElementById('menu')?.focus();
        // logEntry('updateAnswer failed\n' + `matchingQuestions.length:${matchingQuestions.length} q.dataset:${JSON.stringify(q.dataset)}`);
        console.log(`updateAnswer failed: more than one matching question found: ${matchingQuestions.length} questions, q.dataset: ${JSON.stringify(q.dataset)}`);
        // alert('updateAnswer failed\n' + 'matchingQuestions.length:' + matchingQuestions.length + ' q.dataset:' + JSON.stringify(q.dataset));
        return;
      }
      const question = matchingQuestions[0];

      // Get answer from question with highest timeastamp
      if (question.answer && question.answer.length > 0) {
        let lastAnswer: Answer | undefined = undefined;
        for (const ans of question.answer) {
          if (!lastAnswer || lastAnswer.created < ans.created) {
            lastAnswer = ans;
          }
        }
        if (lastAnswer) {
          if (lastAnswer.properties.find((p) => p.type === AnswerPropertyType.Answer)?.value == answer) {
            // if questiontype is text or note, then skip update
            if (question.properties.find((p) => p.type === QuestionPropertyType.Type)?.value == QuestionType.Text
              || question.properties.find((p) => p.type === QuestionPropertyType.Type)?.value == QuestionType.Note
              || question.properties.find((p) => p.type === QuestionPropertyType.Type)?.value == QuestionType.Number
              || question.properties.find((p) => p.type === QuestionPropertyType.Type)?.value == QuestionType.Date
              || question.properties.find((p) => p.type === QuestionPropertyType.Type)?.value == QuestionType.DateTime
              || question.properties.find((p) => p.type === QuestionPropertyType.Type)?.value == QuestionType.Email) {
              return;
            }
          }
        }
      }

      if (Qutils.readonly(question)) {
        document.getElementById('menu')?.focus();
        // alert('Answer is read only');
        return;
      }

      if (
        ((answer || answer === 0) && question)
        || (question
          && (q.dataset.checklist == 'Menu'
            || q.dataset.checklist.match(/^SubMenu/)))
      ) {
        try {
          const value: string | number = question.properties.find((i) => i.type == QuestionPropertyType.OnAnswer)
            ?.value || '';
          if (question) {
            if (value > '') {
              eval(value as string);
            }
          }
        } catch (e) {
          logEntry(`${JSON.stringify(e)} ${e}`);
          console.log(e);
          console.log(`OnAnswer: QuestionID ${JSON.stringify(question.id)}${(e as Error).message}`);
        }
      }

      const localAnswer: Answer = {
        id: 0,
        created: Math.floor(now as number / 1000),
        parent_id: parseInt(q.dataset.questionid),
        item_id: parseInt(q.dataset.itemid),
        checklist_id: parseInt(q.dataset.checklistid),
        seq_no: parseInt(q.dataset.seqno),
        properties: new CustomArray<Property>(
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.Answer,
            value: answer as string,
          },
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.AnsweredBy,
            value:
              window.activeUser.properties.find(
                (p) => p.type === UserPropertyType.Email,
              )?.value || '',
          },
        ),
        file: q.files && !qty ? q.files : [],
      };

      if (!qty && typeof qty !== 'number') {
        qty = 1;
      }
      // check if question is modified from default
      const originalQuestion = window.Data.question.find((quest) => quest.id === question.id);
      if (originalQuestion) {
        // filter properties with id=1 from question.properties (temporary properties)
        const questionProperties = question.properties.filter((p) => p.id !== 1);
        const modified = !this.comparePropertiesAreTheSame(originalQuestion.properties, questionProperties);
        const originalSequnceNumber = originalQuestion.properties.find((p) => p.type === QuestionPropertyType.SequenceNumber)?.value;
        let modified2 = false;
        if (q.dataset.seqno != originalSequnceNumber) {
          modified2 = true;
        }
        if (modified || modified2) {
          // if modified, add custom property to answer
          localAnswer.properties.push({
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.CustomQuestionProperties,
            value: JSON.stringify(question.properties),
          });
        }
      }

      if (qty) {
        localAnswer.properties.push(
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.Qty,
            value: qty,
          },
        );
      }

      if (
        !q.dataset.checklist.match(/^Menu/)
        && !q.dataset.checklist.match(/^SubMenu/)
        && !q.dataset.checklist.match(/^CP2_/)
      ) {
        if (answer?.toString().length || answer === '') {
          if (qty) {
            localAnswer.properties.push({
              id: 0,
              parent_id: 0,
              type: AnswerPropertyType.Qty,
              value: qty.toString(),
            });
          }

          // Find all matching questions in all checklists and products
          matchingQuestions = [];
          for (let o = 0; o < window.Data.order.length; o++) {
            const order = window.Data.order[o];
            if (order.id == parseInt(q.dataset.orderid)) {
              for (let i = 0; i < order.item.length; i++) {
                const item = order.item[i];
                if (item.id == parseInt(q.dataset.itemid) && item.product) {
                  for (let p = 0; p < item.product.length; p++) {
                    const product = item.product[p];
                    if (product.id == parseInt(q.dataset.productid) && product.checklist) {
                      for (let c = 0; c < product.checklist.length; c++) {
                        const checklist = product.checklist[c];
                        if (checklist.id == parseInt(q.dataset.checklistid) && checklist.question) {
                          for (let qIndex = 0; qIndex < checklist.question.length; qIndex++) {
                            const quest = checklist.question[qIndex];
                            if (quest.id == parseInt(q.dataset.questionid)
                              && (quest._seqno == parseInt(q.dataset.seqno) || 0)) {
                              matchingQuestions.push(quest);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          if (matchingQuestions.length !== 1
          ) {
            document.getElementById('menu')?.focus();
            logEntry(`updateAnswer failed\n matchingQuestions.length:${matchingQuestions.length} q.dataset:${JSON.stringify(q.dataset)}`);
            // alert('updateAnswer failed\n' + 'matchingQuestions.length:' + matchingQuestions.length + ' q.dataset:' + JSON.stringify(q.dataset));
          } else {
            if (!matchingQuestions[0].answer) {
              matchingQuestions[0].answer = new CustomArray<Answer>();
            }
            matchingQuestions[0].answer?.push(localAnswer);
            // Push answer to server
            console.log('updateAnswer end');
            await setData('OrderID', JSON.stringify(window.activeOrder));
            await setData('Order', JSON.stringify(window.Data.order));
            await this.renderall();
            await setData('OrderID', JSON.stringify(window.activeOrder));
            await setData('Order', JSON.stringify(window.Data.order));
          }
        }
      } else {
        await utils.renderall();
      }
      await this.renderall();
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      document.getElementById('menu')?.focus();
    }
  }

  async renderall() {
    // Only redraw if there is no text selection active (to not overwrite any pending text copy operations)
    // await new Promise(resolve => setTimeout(resolve, 100));
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.dataset.dummy = '';
    }

    await this.checkOrderForCustomArray(window.Data.order);

    if (
      window.getSelection()?.type !== 'Range'
      && !this.holdRendering
      && activeElement?.dataset.question !== 'q'
    ) {
      // Remove unused orders
      const orders = window.Data.order;
      const ordersLength = orders.length;

      for (let i = orders.length - 1; i >= 0; i--) {
        if (this.orderSynced(orders[i]) && window.activeOrder != i) {
          orders.splice(i, 1);
          if (i < window.activeOrder) {
            window.activeOrder -= 1;
            await setData('OrderID', JSON.stringify(window.activeOrder));
          }
        }
      }

      if (window.activeOrder > window.Data.order.length - 1) {
        window.activeOrder = window.Data.order.length - 1;
        const order = window.Data.order[window.activeOrder];
        if (order) {
          for (const prop of order.properties) {
            // if name value ends with Menu, then it is a menu
            if (
              prop.type == OrderPropertyType.Name
              && prop.value.toString().match(/^Menu/)
            ) {
              window.activeOrder = -1;
              await setData('OrderID', JSON.stringify(window.activeOrder));
            }
          }
        }
      }

      if (ordersLength != window.Data.order.length) {
        await setData('Order', JSON.stringify(window.Data.order));
      }

      const menuorder = window.Data.order.find((order) => {
        for (const prop of order.properties) {
          if (
            prop.type == OrderPropertyType.Name
            && prop.value == 'Menu'
          ) {
            return true;
          }
        }
        return false;
      });

      const submenuorder = window.Data.order.find((order) => {
        for (const prop of order.properties) {
          if (
            prop.type == OrderPropertyType.Name
            && prop.value == window.Data.submenu
          ) {
            return true;
          }
        }
        return false;
      });

      const render = new Renderer();
      let text = '';
      let menutext = '';
      let submenutext = '';

      const checklist = document.getElementById('checklist');
      const menu = document.getElementById('menu');

      if (submenuorder) {
        text = await render.redraw(submenuorder);
        submenutext = text;
      }

      if (menuorder) {
        text = await render.redraw(menuorder);
        menutext = `${text}<div id="submenu">${submenutext}</div>${document.getElementById('googlelogin')?.outerHTML || ''}<div id="googlelogout"><div class="g_id_signout" data-type="standard"><button id="signout_button" onclick="logoutHandler()">Sign out</button></div></div>`;
        // const button = document.getElementById('signout_button');
        // if (button) {
        //   button.onclick = async () => {
        //     await deleteAllData();
        //     document.cookie = 'loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        //     window.location.href = window.location.origin + '/';
        //   };
        // }
      }

      if (
        window.Data.order[window.activeOrder]
        && window.Data.order[window.activeOrder].item) {
        for (const prop of window.Data.order[window.activeOrder].properties) {
          // if name value ends with Menu, then it is a menu
          if (
            prop.type == OrderPropertyType.Name
            && (prop.value.toString().match(/^Menu/) || prop.value.toString().match(/^SubMenu/))
          ) {
            window.activeOrder = -1;
            await setData('OrderID', JSON.stringify(window.activeOrder));
          }
        }
      }

      if (
        window.Data.order[window.activeOrder]
        && window.Data.order[window.activeOrder].item
        && window.Data.order[window.activeOrder] != menuorder
        && window.Data.order[window.activeOrder] != submenuorder
      ) {
        // Show filters again
        const filters = document.getElementsByClassName('filter-hidden');
        for (let i = 0; i < filters.length; i += 1) {
          filters[i].className = 'filter-visible';
        }

        let html = '';
        html = await render.redraw(window.Data.order[window.activeOrder]);
        if (checklist !== null) {
          checklist.innerHTML = `<h4 id="orderid">Order id:<br> ${window.Data.order[window.activeOrder].properties.find(
            (p) => p.type == OrderPropertyType.ERPorderid,
          )?.value || window.Data.order[window.activeOrder].properties.find(
            (p) => p.type == OrderPropertyType.Name,
          )?.value || ''
            }</h4><br>${html}`;
        }
        const head = document.getElementById('orderid');

        if (head) {
          head.onclick = () => {
            const textToCopy = head.innerText.replace('Order id:', '').trim();

            if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
              navigator.clipboard.writeText(textToCopy)
                .catch((err) => {
                  console.error('Failed to copy text: ', err);
                });
            }
          };
        }
      }
      if (menu !== null) {
        menu.innerHTML = menutext;
      }
      if (window.activeOrder < 0) {
        openMenu();
      }
    } else {
      // If there is a text selection active, do not redraw
      setTimeout(async () => this.renderall(), 200); // Try again in 200ms
    }
  }

  getZeroIDs(o: Order | object, findKey: string): Array<number> {
    const output: number[] = [];
    const entries = Object.entries(o);
    for (let i = 0; i < entries.length; i++) {
      const [key, value] = entries[i];
      if (key === findKey && value < 1) {
        output.push(value);
      } else if (typeof value === 'object' && value !== null) {
        output.push(...this.getZeroIDs(value, findKey));
      }
    }
    return output;
  }

  orderSynced(order: Order) {
    const orderName = order.properties.find(
      (p) => p.type == OrderPropertyType.Name,
    )?.value.toString() || '';
    if (orderName.match(/^Menu/) || orderName.match(/^SubMenu/) || this.getZeroIDs(order, 'id').length > 0) {
      return false;
    }
    return true;
  }

  required(question: Question) {
    const _property = question.properties.find(
      (i) => i.type == QuestionPropertyType.Required,
    );
    try {
      if (_property && _property.value.toString().length) {
        return eval(_property.value as string);
      }
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      console.log(`required error: ${question.id}${(e as Error).message}`);
    }
    return false;
  }

  orderCompleted(order: Order): boolean {
    for (const item of order.item) {
      for (const product of item.product) {
        for (const checklist of product.checklist) {
          for (const question of checklist.question) {
            let relevant = true;
            try {
              const value: string = question.properties.find((i) => i.type == QuestionPropertyType.Relevant)
                ?.value.toString() || '';
              if (value > '' && !value.match('orderCompleted\\(')) { // Avoid infinite loop
                const result = new Function(value)();
                if (!result) {
                  relevant = false;
                }
              }
              if (value.match('orderCompleted\\(')) {
                relevant = false;
              }
            } catch (e) {
              logEntry(`${JSON.stringify(e)} ${e}`);
              console.log(`Relevant: QuestionID ${JSON.stringify(question.id)}${(e as Error).message}`);
            }
            if (
              !question.answer?.length
              && this.required(question)
              && relevant
            ) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  public sortJSONbyKeyNameThenByID(obj: { [key: string]: any }): { [key: string]: any } {
    // Base case: if obj is not an object, return it as-is
    if (typeof obj !== 'object' || obj === null) return obj;

    // If it's an array, just return the sorted array (without sorting its keys)
    if (Array.isArray(obj)) return obj.map(this.sortJSONbyKeyNameThenByID.bind(this));

    // Bind the method outside the reduce for usage inside
    const boundMethod = this.sortJSONbyKeyNameThenByID.bind(this);

    // Otherwise, sort the object keys
    return Object.keys(obj)
      .sort((a, b) => {
        // Compare by key name first
        if (a < b) return -1;
        if (a > b) return 1;

        // If key names are equal, compare by key value (assuming they are numbers)
        return (a as any).id - (b as any).id;
      })
      .reduce<{ [key: string]: any }>((acc, key) => ({
        ...acc,
        [key]: boundMethod(obj[key]), // Recursive call for nested objects
      }), {});
  }

  async syncItems(): Promise<number> {
    let update = 0;
    const deletedItems = utils.getDeletedItems();
    if (deletedItems.length > 0) {
      const batchData = deletedItems.map((item) => ({
        id: item.id,
        parent_id: item.parent_id,
        properties: item.properties,
      }));
      const response = await Backend('items', HTTPMethods.DELETE, JSON.stringify(batchData));
      if (!response.ok) {
        console.log(response.statusText);
      } else {
        for (const order of window.Data.order) {
          update += 1;
          order.item.assign(order.item.filter((i) => !deletedItems.some((di) => di.id === i.id)));
        }
      }
    }
    const newItems = utils.getNewItems();
    if (newItems.length > 0) {
      const formattedItems = newItems.map((item) => ({
        id: item.id,
        parent_id: item.parent_id,
        properties: item.properties,
      }));

      const response = await Backend('items', HTTPMethods.POST, JSON.stringify(formattedItems));
      if (!response.ok) {
        console.log(response.statusText);
      } else {
        update += 1;
        const updatedItems = await response.json() as Item[];
        for (let ii = 0; ii < updatedItems.length; ii++) {
          const updatedItem = updatedItems[ii];
          const originalItem = newItems[ii];

          originalItem.id = updatedItem.id;
          originalItem.parent_id = updatedItem.parent_id;
          for (let i = 0; i < originalItem.properties.length; i++) {
            if (updatedItem.properties.length && !!updatedItem.properties[i]) {
              originalItem.properties[i].parent_id = originalItem.id;
              originalItem.properties[i].id = updatedItem.properties[i].id;
            }
          }
          if (originalItem.product) {
            for (const product of originalItem.product) {
              if (product.checklist) {
                for (const checklist of product.checklist) {
                  if (checklist.question) {
                    for (const question of checklist.question) {
                      if (question.answer) {
                        for (const answer of question.answer) {
                          if (question._seqno === answer.seq_no) {
                            answer.item_id = originalItem.id;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    const deletedItemProperties = utils.getDeletedItemProperties();
    if (deletedItemProperties.length > 0) {
      const batchData = deletedItemProperties.map((itemproperty) => ({
        id: itemproperty.id,
        parent_id: itemproperty.parent_id,
        type: itemproperty.type,
        value: itemproperty.value,
      }));
      const response = await Backend('itemProperties', HTTPMethods.DELETE, JSON.stringify(batchData));
      if (!response.ok) {
        console.log(response.statusText);
      } else {
        update += 1;
        for (const order of window.Data.order) {
          for (const item of order.item) {
            item.properties.assign(item.properties.filter((p) => !deletedItemProperties.some((dp) => dp.id === p.id)));
          }
        }
      }
    }

    const newItemProperties = utils.getNewItemProperties();
    if (newItemProperties.length > 0) {
      for (const itemproperty of newItemProperties) {
        let httpMethod = HTTPMethods.POST;
        if (itemproperty && itemproperty._updated) {
          httpMethod = HTTPMethods.PUT;
        }
        // Remove _updated field
        const data: Property[] = [{
          id: itemproperty.id,
          parent_id: itemproperty.parent_id,
          type: itemproperty.type,
          value: itemproperty.value,
        }];
        const response = await Backend('itemProperties', httpMethod, JSON.stringify(data));
        if (!response.ok) {
          console.log(response.statusText);
        } else {
          const json = await response.json() as Property[];
          const _ret = json[0];
          itemproperty.id = _ret.id;
          itemproperty.parent_id = _ret.parent_id;
          if (itemproperty._updated) {
            itemproperty._updated = undefined;
          }
        }
      }
    }
    return update;
  }

  async syncData() {
    const utils = new ChecklistUtils();
    let update = 0;

    if (window.syncing) {
      // setTimeout(async () => await utils.syncData(), serverRetryTime);
      return;
    }
    window.syncing = true;

    try {
      console.log('syncing data to backend');
      for (const order of utils.getNewOrders()) {
        const _order = [
          {
            id: order.id,
            parent_id: order.parent_id,
            properties: order.properties,
          },
        ];
        const response = await Backend('orders', HTTPMethods.POST, JSON.stringify(_order));
        if (!response.ok) {
          console.log(response.statusText);
        } else {
          const json = await response.json() as Order[];
          const _ret = json[0];
          order.id = _ret.id;
          order.parent_id = _ret.parent_id;
          order.properties = _ret.properties;
          for (const item of order.item ?? []) {
            item.parent_id = order.id;
            for (const product of item.product ?? []) {
              for (const checklist of product.checklist ?? []) {
                checklist.parent_id = order.id;
              }
            }
          }
          await setData('Order', JSON.stringify(window.Data.order));
          update += 1;
        }
      }

      for (const orderproperty of utils.getNewOrderProperties()) {
        let httpMethod = HTTPMethods.POST;
        if (orderproperty && orderproperty._updated) {
          httpMethod = HTTPMethods.PUT;
        }
        // Remove _updated field
        const data: Property[] = [{
          id: orderproperty.id,
          parent_id: orderproperty.parent_id,
          type: orderproperty.type,
          value: orderproperty.value,
        }];
        const response = await Backend('orderProperties', httpMethod, JSON.stringify(data));
        if (!response.ok) {
          console.log(response.statusText);
        } else {
          const json = await response.json() as Property[];
          const _ret = json[0];
          orderproperty.id = _ret.id;
          orderproperty.parent_id = _ret.parent_id;
          if (orderproperty._updated) {
            orderproperty._updated = undefined;
          }
          update += 1;
        }
      }
      update += await this.syncItems();

      const deletedAnswers = utils.getDeletedAnswers();
      if (deletedAnswers.length > 0) {
        for (let i = 0; i < deletedAnswers.length; i += 1) {
          delete deletedAnswers[i].file;
          const res = await this.deleteAnswer(deletedAnswers[i]);
          if (res) {
            // remove answer from order
            for (let o = 0; o < window.Data.order.length; o += 1) {
              const order = window.Data.order[o];
              if (order) {
                for (const item of order.item) {
                  if (item.id === deletedAnswers[i].item_id) {
                    for (const product of item.product) {
                      for (const checklist of product.checklist) {
                        for (const question of checklist.question) {
                          if (deletedAnswers[i].parent_id === question.id) {
                            if (question.answer) {
                              question.answer = question.answer.filter((a) => a.id !== deletedAnswers[i].id) as CustomArray<Answer>;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      const newAnswers = utils.getNewAnswers(true);

      const normalAnswers = newAnswers.filter((a) => !a.file || !a.file?.length).map((a) => ({
        originalAnswer: a,
        answer: {
          id: a.id > 0 ? a.id : 0,
          parent_id: a.parent_id && a.parent_id > 0 ? a.parent_id : 0,
          created: a.created,
          item_id: a.item_id,
          checklist_id: a.checklist_id,
          seq_no: a.seq_no,
          properties: a.properties,
        } as Answer,
      }));
      const fileAnswers = newAnswers.filter((a) => a.file && a.file.length).map((a) => {
        const copy = structuredClone(a);
        const formData = new FormData();

        for (const property of copy.properties) {
          if (property.type == AnswerPropertyType.Answer) {
            property.value = 'filnavn'; // file[0].name;
          }
        }

        formData.append('file', (a.file as FileList | never[])[0]);
        formData.append('JSON', JSON.stringify([{
          id: a.id > 0 ? a.id : 0,
          parent_id: a.parent_id && a.parent_id > 0 ? a.parent_id : 0,
          created: a.created,
          item_id: a.item_id,
          checklist_id: a.checklist_id,
          seq_no: a.seq_no,
          properties: copy.properties,
        } satisfies Answer]));
        return { originalAnswer: a, formData };
      });

      if (normalAnswers.length) {
        // sort answers by created date, oldest first
        normalAnswers.sort((a, b) => a.answer.created - b.answer.created);
        const normalAnswersResponse = await Backend(
          'answers',
          HTTPMethods.POST,
          JSON.stringify(normalAnswers.map((obj) => obj.answer)),
        );
        if (!normalAnswersResponse.ok) {
          console.log(normalAnswersResponse.statusText);
        } else {
          update += 1;
          const json = await normalAnswersResponse.json() as Answer[];

          for (let i = 0; i < normalAnswers.length; i++) {
            const { originalAnswer } = normalAnswers[i];
            const newAnswer = i < json.length ? json[i] : undefined;
            if (newAnswer) {
              originalAnswer.id = newAnswer.id;
              originalAnswer.parent_id = newAnswer.parent_id;
              originalAnswer.properties = newAnswer.properties;
            }
            // TODO Find answer if json lengths differ? Probably not needed - usually only one answer is persisted
          }
        }
      }

      if (fileAnswers.length) {
        for (const pair of fileAnswers) {
          const response = await Backend(
            'answers',
            HTTPMethods.POST,
            undefined,
            pair.formData,
          );
          if (!response.ok) {
            console.log(response.statusText);
          } else {
            update += 1;
            const json = await response.json() as Answer[];
            const newAnswer = json[0];
            pair.originalAnswer.id = newAnswer.id;
            pair.originalAnswer.parent_id = newAnswer.parent_id;
            pair.originalAnswer.properties = newAnswer.properties;
            delete pair.originalAnswer.file;
          }
        }
      }
      console.log('finished syncing data');
      await utils.renderall();
      await setData('Order', JSON.stringify(window.Data.order));
    } catch (error) {
      console.log(`syncData ERROR: ${(error as Error).message}`);
    } finally {
      window.syncing = false;
      await utils.renderall();
      if (update > 0) {
        utils.syncData();
      }
    }
  }

  removeDuplicateAnswerIDs(order: Order) {
    const answerIDs: number[] = [];
    for (const item of order.item) {
      for (const product of item.product) {
        for (const checklist of product.checklist) {
          for (const question of checklist.question) {
            if (question.answer) {
              for (const answer of question.answer) {
                if (answerIDs.indexOf(answer.id) === -1) { //
                  answerIDs.push(answer.id);
                } else {
                  // remove answer from question
                  question.answer = question.answer.filter((a) => a.id !== answer.id) as CustomArray<Answer>;
                }
              }
            }
          }
        }
      }
    }
  }

  async updateSeqNo(order: Order) {
    const used_questions: string[] = [];
    for (const item of order.item) {
      for (const product of item.product) {
        if (product.checklist) {
          for (const checklist of product.checklist) {
            if (checklist.question) {
              for (const question of checklist.question) {
                question._seqno = parseInt(question.properties.find(
                  (property) => property.type == QuestionPropertyType.SequenceNumber,
                )?.value.toString() || '0');
                question._checklistid = checklist.id;
                question._checklist = checklist.properties.find((property) => property.type == ChecklistPropertyType.Name)?.value.toString();
                if (question.answer) {
                  for (const answer of question.answer) {
                    if (used_questions.indexOf(JSON.stringify(answer)) == -1) { // if not already in array
                      const prop = answer.properties.find((p) => p.type == AnswerPropertyType.CustomQuestionProperties);
                      if (prop) {
                        const customQuestionProperties = JSON.parse(prop.value.toString()) as Property[];
                        if (customQuestionProperties.length > 0) {
                          question.properties = customQuestionProperties;
                          const _seqno = customQuestionProperties.find((property) => property.type == QuestionPropertyType.SequenceNumber)?.value;
                          if (_seqno) {
                            if (question._seqno != parseInt(_seqno.toString())) {
                              question._seqno = parseInt(_seqno.toString());
                              used_questions.push(JSON.stringify(answer));
                              break;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  async checkOrderForCustomArray(orders: Order[]) {
    for (const order of orders) {
      order.item = new CustomArray<Item>(...order.item);
      if (order.item) {
        for (const item of order.item ?? []) {
          if (item.product) {
            item.product.forEach((product) => {
              if (product.checklist) {
                product.checklist.forEach((checklist) => {
                  for (const question of checklist.question ?? []) {
                    for (const answer of question.answer ?? []) {
                      answer.properties = new CustomArray<Property>(...answer.properties);
                    }
                    if (question.answer) question.answer = new CustomArray<Answer>(...question.answer);
                  }
                });
              }
            });
          }
          item.properties = new CustomArray<Property>(...item.properties);
        }
      }
      order.properties = new CustomArray<Property>(...order.properties);
    }
  }

  async openOrder(
    orderid: string | undefined,
    organization?: string,
    id?: string,
    silent?: boolean,
    search?: { term: string, type: OrderPropertyType },
  ) {
    if ((!orderid || orderid.trim() === '') && !id && !search) {
      return;
    }
    const utils = new ChecklistUtils();
    let url = `Mamut_order/${organization}/${orderid}`;
    if (!organization) {
      // url = `orders/parameter/1/find/${orderid}`;
      url = `orders/find/${orderid}`;
    }
    if (id) {
      url = `orders/${id}`;
    }
    if (orderid && orderid.match(/^CP2_/)) {
      url = `CP2_order/${organization}/${orderid}`;
    }
    if (search) {
      url = `orders/parameter/${search.type}/find/${search.term}`;
    }
    await Backend(encodeURI(url), HTTPMethods.GET, undefined, undefined, silent ? true : undefined)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then((response) => {
        const data = JSON.parse(response);
        return data;
      })
      .then(async (response: Order[]) => {
        let orders = response;
        if (orders.length > 0) {
          orders = response.filter((order) => order.item.length > 0);
        }

        if (orders.length > 0) {
          for (let i = orders.length - 1; i >= 0; i--) {
            if (!orders[i].id) {
              orders.splice(i, 1);
            }
          }
        }

        await this.checkOrderForCustomArray(orders);

        if (orders.length === 1 || orderid?.match(/^Submenu/)) {
          if (!silent) {
            console.log(`Order ${url} found.`);
          }
          for (const o of orders) {
            const existingOrder = window.Data.order.find(
              (order: Order) => order.id === o.id,
            );
            await utils.updateSeqNo(o);
            utils.removeDuplicateAnswerIDs(o);
            const orderName = o.properties.find((p) => p.type === OrderPropertyType.Name)?.value.toString() || '';
            if (o.item) {
              for (const item of o.item) {
                const salesorderName = utils.getAnswer('salesreference', item);
                if (salesorderName) {
                  utils.addAssosiatedQuestions(o, salesorderName.toString());
                }
              }
            }

            if (!existingOrder) {
              window.Data.order.push(o);
              if (
                !orderName.match(/^Menu/)
                && !orderName.match(/^SubMenu/)
              ) {
                // if (!silent) {
                window.activeOrder = window.Data.order.length - 1;
                await setData(
                  'OrderID',
                  JSON.stringify(window.activeOrder),
                );
                // }
              }
            } else {
              for (let index = 0; index < window.Data.order.length; index += 1) {
                const order = window.Data.order[index];
                if (order.id === o.id) {
                  window.Data.order[index] = o;
                  if (
                    !orderName.match(/^Menu/)
                    && !orderName.match(/^SubMenu/)
                  ) {
                    // if (!silent) {
                    window.activeOrder = index;
                    await setData(
                      'OrderID',
                      JSON.stringify(window.activeOrder),
                    );
                    // }
                  }
                }
              }
            }
            await setData('Order', JSON.stringify(window.Data.order));
          }
          if ((!orderid?.match(/^Menu/) && !orderid?.match(/^Submenu/)) || response.length === 1) {
            closeMenu();
          }
          await utils.renderall();
        } else if (orders.length > 1) {
          utils.selectOrder(orders);
        } else {
          document.getElementById('menu')?.focus();
          if (!silent) {
            alert(`order '${url}' not found`);
          }
        }
        if (!silent) {
          console.log('order opened.');
        }
      })
      .catch(async (error) => {
        document.getElementById('menu')?.focus();
        if (!silent) {
          alert('Order not found.');
        }
        await utils.renderall();
        console.log(error);
      });
  }

  addAssosiatedQuestions(o: Order, orderid: string) {
    if ((!orderid || orderid.trim() == '')) {
      return;
    }

    const url = `orders/find/${orderid}`;

    Backend(encodeURI(url), HTTPMethods.GET, undefined, undefined, true)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then((response) => {
        const data = JSON.parse(response);
        return data;
      })
      .then(async (response: Order[]) => {
        if (response.length > 0) {
          response = response.filter((order) => order.item.length > 0);
        }
        if (response.length === 1) {
          // In response, remove all questions without Specialhandling = 'Copy from salesorder'
          for (const order of response) {
            for (const item of order.item) {
              for (const product of item.product) {
                for (const checklist of product.checklist) {
                  for (const p of checklist.properties) {
                    if (p.type == ChecklistPropertyType.Name) {
                      p.value = 'N/A';
                    }
                  }

                  checklist.question = checklist.question.filter((question) => {
                    const specialhandlingProp = question.properties.find((p) => p.type == QuestionPropertyType.SpecialHandling);
                    const readonlyProp = question.properties.find((p) => p.type == QuestionPropertyType.WriteProtected);
                    const specialhandling = (specialhandlingProp && specialhandlingProp.value.toString()) || '';
                    const readonly = (readonlyProp && readonlyProp.value.toString()) || '';

                    if (readonly.length > 0) {
                      for (const p of question.properties) {
                        if (p.type == QuestionPropertyType.WriteProtected) {
                          p.value = '1';
                        }
                      }
                    } else {
                      // add readonly property
                      question.properties.push({
                        id: 1,
                        parent_id: question.id,
                        type: QuestionPropertyType.WriteProtected,
                        value: '1',
                      });
                    }
                    return specialhandling.match(/Copy to order/);
                  });
                }
              }
            }
          }
          const salesorderquestions = response[0];
          if (salesorderquestions) {
            // merge salesorderquestions with o
            for (const item of salesorderquestions.item) {
              item.parent_id = o.id;
              for (const product of item.product ?? []) {
                for (const checklist of product.checklist ?? []) {
                  checklist.parent_id = o.id;
                  for (const question of checklist.question ?? []) {
                    question._order_id = o.id;
                  }
                }
              }
              o.item.push(item);
            }
          }
          await utils.renderall();
        }
      });
  }

  // get parentOrder from answerid
  async parentOrder(id: string) {
    const utils = new ChecklistUtils();
    const url = `parentOrder/${id}`;
    // closeMenu();
    await Backend(encodeURI(url), HTTPMethods.GET)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then((response) => {
        const data = JSON.parse(response);
        return data;
      })
      .then(async (response: Order[]) => {
        if (response.length > 0) {
          for (let i = response.length - 1; i >= 0; i--) {
            if (!response[i].id) {
              response.splice(i, 1);
            }
          }
        }
        if (response.length == 1) {
          console.log(`ParentOrder ${id} found.`);
          for (let i = 0; i < response.length; i++) {
            const o = response[i];
            const existingOrder = window.Data.order.find((order: Order) => order.id == o.id);

            utils.updateSeqNo(o);

            if (!existingOrder) {
              window.Data.order.push(o);
              window.activeOrder = window.Data.order.length - 1;
              await setData('OrderID', JSON.stringify(window.activeOrder));
            } else {
              for (let j = 0; j < window.Data.order.length; j++) {
                if (window.Data.order[j].id == o.id) {
                  window.Data.order[j] = o;
                  window.activeOrder = j;
                  await setData('OrderID', JSON.stringify(window.activeOrder));
                  break;
                }
              }
            }
          }

          await utils.renderall();
        } else if (response.length > 1) {
          utils.selectOrder(response);
        } else {
          document.getElementById('menu')?.focus();
          alert(`ParentOrder '${id}' not found`);
        }
        console.log('ParentOrder opened.');
      })
      .catch(async (error) => {
        document.getElementById('menu')?.focus();
        alert('ParentOrder not found.');
        await utils.renderall();
        console.log(error);
      });
  }

  async APIcall(
    url: string,
    method?: HTTPMethods,
    data?: string,
    formData?: FormData,
  ) {
    // closeMenu();
    let result;
    await Backend(encodeURI(url), method || HTTPMethods.GET, data, formData)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then((response) => {
        const responseData = JSON.parse(response);
        return responseData;
      })
      .then((response: unknown[]) => {
        result = response;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
    return result;
  }

  selectOrder(response: Order[]) {
    // Display select in div with id popup
    const utils = new ChecklistUtils();
    const popup = document.getElementById('popup') as HTMLDivElement;
    popup.innerHTML = '';
    const select = document.createElement('select');
    select.id = 'select';
    select.onchange = () => {
      const id = select.value;
      utils.openOrder(id, undefined, id);
      popup.style.display = 'none';
    };
    const option = document.createElement('option');
    option.value = '';
    select.appendChild(option);
    for (let i = 0; i < response.length; i++) {
      const order: Order = response[i];
      if (order.id) {
        const option2 = document.createElement('option');
        option2.value = order.id.toString();
        option2.innerHTML = `${order.properties
          .find((property) => property.type === OrderPropertyType.ERPorderid)
          ?.value.toString() || ''
          } ${order.properties
            .find((property) => property.type === OrderPropertyType.Name)
            ?.value.toString() || ''
          } ${order.properties
            .find((property) => property.type === OrderPropertyType.Organization)
            ?.value.toString() || ''}`;
        select.appendChild(option2);
      }
    }
    const heading3 = document.createElement('h4');
    heading3.innerHTML = 'Multiple orders found';
    const heading1 = document.createElement('h3');
    heading1.innerHTML = 'Select order';
    // add cancel button
    const button = document.createElement('button');
    button.innerHTML = 'Cancel';
    button.onclick = () => {
      popup.style.display = 'none';
    };
    popup.appendChild(heading1);
    popup.appendChild(heading3);
    popup.appendChild(document.createElement('hr'));
    popup.appendChild(select);
    popup.style.display = 'block';
  }

  getNewAnswers(fixIds?: boolean): Answer[] {
    const localAnswers: Answer[] = [];

    for (let o = 0; o < window.Data.order.length; o++) {
      const order = window.Data.order[o];
      const _ordername = order.properties.find((i) => i.type == OrderPropertyType.Name)?.value;
      if (_ordername != 'Menu' && _ordername != 'Submenu') {
        for (let it = 0; order.item && it < order.item.length; it++) {
          const item = order.item[it];
          for (let pr = 0; item.product && pr < item.product.length; pr++) {
            const product = item.product[pr];
            for (let ch = 0; product.checklist && ch < product.checklist.length; ch++) {
              const checklist = product.checklist[ch];
              for (let qn = 0; checklist.question && qn < checklist.question.length; qn++) {
                const question = checklist.question[qn];
                for (let an = 0; question.answer && an < question.answer.length; an++) {
                  const answer = question.answer[an];
                  if (answer.id < 1) {
                    if (fixIds) {
                      if (answer.parent_id < 1) {
                        answer.parent_id = question.id;
                      }
                      if (answer.item_id < 1) {
                        answer.item_id = item.id;
                      }
                      if (answer.checklist_id < 1) {
                        answer.checklist_id = checklist.id;
                      }
                    }
                    if (answer.item_id > 0 && answer.checklist_id > 0 && answer.parent_id > 0) {
                      localAnswers.push(answer);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return localAnswers;
  }

  getDeletedAnswers(): CustomArray<Answer> {
    const localAnswers: CustomArray<Answer> = new CustomArray<Answer>();

    for (let o = 0; o < window.Data.order.length; o++) {
      const order = window.Data.order[o];
      const _ordername = order.properties.find((i) => i.type == OrderPropertyType.Name)?.value;
      if (_ordername != 'Menu' && _ordername != 'Submenu') {
        for (let it = 0; order.item && it < order.item.length; it++) {
          const item = order.item[it];
          for (let pr = 0; item.product && pr < item.product.length; pr++) {
            const product = item.product[pr];
            for (let ch = 0; product.checklist && ch < product.checklist.length; ch++) {
              const checklist = product.checklist[ch];
              for (let qn = 0; checklist.question && qn < checklist.question.length; qn++) {
                const question = checklist.question[qn];
                for (let an = 0; question.answer && an < question.answer.length; an++) {
                  const answer = question.answer[an];
                  if (answer.created < 0) {
                    localAnswers.push(answer);
                  }
                }
              }
            }
          }
        }
      }
    }
    return localAnswers;
  }

  getNewItems(): Item[] {
    const _items = new CustomArray<Item>();

    for (let o = 0; o < window.Data.order.length; o++) {
      const order = window.Data.order[o];
      for (let it = 0; order.item && it < order.item.length; it++) {
        const item = order.item[it];
        if (item.id < 1) {
          _items.push(item);
        }
      }
    }
    return _items;
  }

  getDeletedItems(): Item[] {
    const _items = new CustomArray<Item>();

    for (let o = 0; o < window.Data.order.length; o++) {
      const order = window.Data.order[o];
      for (let it = 0; order.item && it < order.item.length; it++) {
        const item = order.item[it];
        if (item._delete) {
          _items.push(item);
        }
      }
    }
    return _items;
  }

  getNewItemProperties(): Property[] {
    const _itemProperties = new CustomArray<Property>();

    for (let o = 0; o < window.Data.order.length; o++) {
      const order = window.Data.order[o];
      for (let it = 0; order.item && it < order.item.length; it++) {
        const item = order.item[it];
        for (let pr = 0; item.properties && pr < item.properties.length; pr++) {
          const property = item.properties[pr];
          if (property.id < 1 || property._updated) {
            if (property.parent_id < 1) {
              property.parent_id = item.id;
            }
            _itemProperties.push(property);
          }
        }
      }
    }
    return _itemProperties;
  }

  getDeletedItemProperties(): Property[] {
    const _itemProperties = new CustomArray<Property>();

    for (let o = 0; o < window.Data.order.length; o++) {
      const order = window.Data.order[o];
      for (let it = 0; order.item && it < order.item.length; it++) {
        const item = order.item[it];
        for (let pr = 0; item.properties && pr < item.properties.length; pr++) {
          const property = item.properties[pr];
          if (property._delete) {
            _itemProperties.push(property);
          }
        }
      }
    }
    return _itemProperties;
  }

  getNewOrders(): Order[] {
    const _orders = new CustomArray<Order>();

    for (let o = 0; o < window.Data.order.length; o++) {
      const order = window.Data.order[o];
      if (order.id < 1) {
        _orders.push(order);
      }
    }
    return _orders;
  }

  getNewOrderProperties(): Property[] {
    const _orderProperties = new CustomArray<Property>();

    for (let o = 0; o < window.Data.order.length; o++) {
      const order = window.Data.order[o];
      for (let pr = 0; order.properties && pr < order.properties.length; pr++) {
        const property = order.properties[pr];
        if (property.id < 1 || property._updated) {
          _orderProperties.push(property);
        }
      }
    }
    return _orderProperties;
  }

  getERPprod(ERPprodid: string): Product {
    let prod = {} as Product;
    for (const product of window.Data.product) {
      if (
        product.properties.find((i) => i.type == ProductPropertyType.ERPprodid)
          ?.value == ERPprodid
      ) {
        // return copy of product;
        prod = JSON.parse(JSON.stringify(product));
        return prod;
      }
      if (
        product.properties.find((i) => i.type == ProductPropertyType.MRP24SOProdid)
          ?.value == ERPprodid
      ) {
        // return copy of product;
        prod = JSON.parse(JSON.stringify(product));
        return prod;
      }
    }
    return prod;
  }

  sortQuestions(a: Question, b: Question) {
    // Category
    const _A_Category: string | number = a.properties.find((i) => i.type == QuestionPropertyType.Category)?.value
      || '0';
    const _B_Category: string | number = b.properties.find((i) => i.type == QuestionPropertyType.Category)?.value
      || '0';
    if (_A_Category < _B_Category) {
      return -1;
    }
    if (_A_Category > _B_Category) {
      return 1;
    }
    // Instance
    const _A_ItemNumber: number = a._itemid || 0;
    const _B_ItemNumber: number = b._itemid || 0;
    if (_A_ItemNumber < _B_ItemNumber) {
      return -1;
    }
    if (_A_ItemNumber > _B_ItemNumber) {
      return 1;
    }

    // Sequence
    const _A_SequenceNumber: number = parseInt(
      (a.properties.find((i) => i.type == QuestionPropertyType.SequenceNumber)
        ?.value as string) || '0',
    );
    const _B_SequenceNumber: number = parseInt(
      (b.properties.find((i) => i.type == QuestionPropertyType.SequenceNumber)
        ?.value as string) || '0',
    );
    if (_A_SequenceNumber < _B_SequenceNumber) {
      return -1;
    }
    if (_A_SequenceNumber > _B_SequenceNumber) {
      return 1;
    }

    // ID if all other equal, new ids last
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }

  sortAnswers(a: Answer, b: Answer) {
    const A: number = a.created;
    const B: number = b.created;
    if (A < B) {
      return 1;
    }
    if (A > B) {
      return -1;
    }
    return 0;
  }

  instance(q: Question): number {
    return q._itemid || 0;
  }

  isNumeric(str: string): boolean {
    return (
      !Number.isNaN(Number(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    );
  }

  sortQuestionData(Question: Question) {
    const dataproperties = [] as Property[];
    for (let i = 0; i < Question.properties.length; i++) {
      const property = Question.properties[i];
      if (property.type == QuestionPropertyType.Data) {
        dataproperties.push(property);
      }
    }

    Question.properties = Question.properties.filter((i) => i.type != QuestionPropertyType.Data);
    dataproperties.sort((a, b) => {
      try {
        const A = JSON.parse(a.value.toString());
        const B = JSON.parse(b.value.toString());
        let sortTextA = '';
        let sortTextB = '';
        if (A.Item[window.activelanguage].length) {
          sortTextA = A.Item[window.activelanguage];
        } else {
          sortTextA = A.Item.NOR;
        }
        if (B.Item[window.activelanguage].length) {
          sortTextB = B.Item[window.activelanguage];
        } else {
          sortTextB = B.Item.NOR;
        }
        if (!Number.isNaN(Number(sortTextA)) && !Number.isNaN(parseFloat(sortTextA))
          && !Number.isNaN(Number(sortTextB)) && !Number.isNaN(parseFloat(sortTextB))) {
          return parseFloat(sortTextA) - parseFloat(sortTextB);
        }
        return sortTextA.localeCompare(sortTextB);
      } catch (e) {
        logEntry(`${JSON.stringify(e)} ${e}`);
        return 0;
      }
    });
    Question.properties = Question.properties.concat(dataproperties);
  }

  async addQuestionProperty(
    question: Question,
    type: QuestionPropertyType,
    value: string,
  ) {
    const _property: Property = {
      id: 0,
      parent_id: question.id,
      type,
      value,
    };
    question.properties.push(_property);
    await this.renderall();
  }

  async duplicateQuestion(q: { dataset: Dataset }): Promise<void> {
    const newQuestion: Question | undefined = structuredClone(
      window.Data.order
        .find((i) => i.id == parseInt(q.dataset.orderid))
        ?.item.find((i) => i.id == parseInt(q.dataset.itemid))
        ?.product.find((i) => i.id == parseInt(q.dataset.productid))
        ?.checklist.find((i) => i.id == parseInt(q.dataset.checklistid))
        ?.question.find((Q) => Q.id == parseInt(q.dataset.questionid) && Q._seqno == parseInt(q.dataset.seqno)),
    );
    if (newQuestion) {
      newQuestion.id = parseInt(q.dataset.questionid);
      newQuestion.answer = new CustomArray<Answer>();
      newQuestion.parent_id = parseInt(q.dataset.checklistid);
      newQuestion._order_id = parseInt(q.dataset.orderid);
      newQuestion.properties = newQuestion.properties.filter(
        (i) => i.type != QuestionPropertyType.Required,
      );
      for (const property of newQuestion.properties) {
        property.id = 0;
        property.parent_id = 0;
      }

      const maxSequenceNumber = this.getMaxSequenceNumber(newQuestion);

      for (const property of newQuestion.properties) {
        if (property.type == QuestionPropertyType.SequenceNumber) {
          property.value = maxSequenceNumber + 1;
        }
      }

      window.Data.order
        .find((i) => i.id == parseInt(q.dataset.orderid))
        ?.item.find((i) => i.id == parseInt(q.dataset.itemid))
        ?.product.find((i) => i.id == parseInt(q.dataset.productid))
        ?.checklist.find((i) => i.id == parseInt(q.dataset.checklistid))
        ?.question?.push(newQuestion);
      await this.renderall();
    }
  }

  getMaxSequenceNumber(q: Question): number {
    // Use the previous sequence number if it exists, else 0
    let maxSequenceNumber = Number(q.properties.find((i) => i.type == QuestionPropertyType.SequenceNumber)?.value) ?? 0;

    for (const order of window.Data.order) {
      if (order.item) {
        for (const item of order.item) {
          if (item.product) {
            for (const product of item.product) {
              if (product.checklist) {
                for (const checklist of product.checklist) {
                  if (checklist.question) {
                    for (const question of checklist.question) {
                      if (question.id === q.id) {
                        if (question.answer) {
                          let customdata = undefined;
                          const _res: Answer[] = question.answer?.sort(utils.sortAnswers) || [];
                          if (_res && _res.length > 0) {
                            const localAnswer = _res[0].properties.find(
                              (i) => i.type == AnswerPropertyType.CustomQuestionProperties,
                            );
                            if (localAnswer) {
                              let data;
                              try {
                                data = JSON.parse(localAnswer.value as string);
                              } catch (e) {
                                logEntry(`${JSON.stringify(e)} ${e}`);
                                console.log(`customData error: ${question.id}${(e as Error).message}`);
                                data = undefined;
                              }
                              customdata = data;
                            }
                          }
                          if (customdata) {
                            question.properties = customdata;
                          }
                        }
                        const _sequenceNumber: number = parseInt(
                          (question.properties.find((i) => i.type == QuestionPropertyType.SequenceNumber)
                            ?.value as string) || '',
                        );
                        if (_sequenceNumber > maxSequenceNumber) {
                          maxSequenceNumber = _sequenceNumber;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return maxSequenceNumber + 1;
  }

  async duplicateInstance(
    question: {
      dataset: Dataset;
    },
    keepAnswers?: boolean,
  ): Promise<void> {
    const tempOrder = structuredClone(window.Data.order.find((o) => o.id == parseInt(question.dataset.orderid))) as Order;
    const itemId = parseInt(question.dataset.itemid);
    const orderId = parseInt(question.dataset.orderid);

    // Filter and loop over items
    for (let i = 0; i < tempOrder.item.length; i++) {
      if (tempOrder.item[i].id === itemId) {
        const item = tempOrder.item[i];
        item.id = 0;
        item.parent_id = orderId;
        for (let j = 0; j < item.properties.length; j++) {
          item.properties[j].id = 0;
          item.properties[j].parent_id = 0;
        }
        for (let k = 0; k < item.product.length; k++) {
          const product = item.product[k];
          for (let l = 0; l < product.checklist.length; l++) {
            const checklist = product.checklist[l];
            if (checklist.question) {
              for (let m = 0; m < checklist.question.length; m++) {
                const q = checklist.question[m];
                if (!keepAnswers) {
                  q.answer = new CustomArray<Answer>();
                } else if (q.answer) {
                  for (let n = 0; n < q.answer.length; n++) {
                    const answer = q.answer[n];
                    answer.id = 0;
                    answer.parent_id = 0;

                    for (let o = 0; o < answer.properties.length; o++) {
                      answer.properties[o].id = 0;
                      answer.properties[o].parent_id = 0;
                    }
                  }
                }
                q.id = 0;
                q._itemid = item.id;
                for (let p = 0; p < q.properties.length; p++) {
                  q.properties[p].id = 0;
                }
                const order = window.Data.order.find((o) => o.id === orderId);
                if (order) {
                  const orderItem = order.item.find((it) => it.id === itemId);
                  if (orderItem) {
                    const prod = orderItem.product.find((p) => p.id === parseInt(question.dataset.productid));
                    if (prod) {
                      prod.checklist.push(checklist);
                    }
                  }
                }
              }
            }
          }
        }

        const order = window.Data.order.find((o) => o.id === orderId);
        if (order) {
          order.item.push(item);
        }
      }
    }
    await this.renderall();
  }

  async addProduct(item: Item | null, prod: Product | undefined, once?: boolean, qty?: number, questionId?: number | string, deleteOld?: boolean) {
    if (prod == undefined) {
      return;
    }
    if (Object.keys(prod).length === 0) {
      return;
    }

    // Check if prod is not type of Product
    const isProduct = Object.prototype.hasOwnProperty.call(prod, 'checklist');
    if (!isProduct) {
      return;
    }

    const newItem = {
      id: 0, parent_id: 0, properties: new CustomArray<Property>(), product: new CustomArray<Product>(),
    } as Item;
    if (!item) {
      newItem.id = this.getNextAvailableTemporaryID(newItem);
      newItem.parent_id = window.Data.order[window.activeOrder].id;
      item = newItem;
      window.Data.order[window.activeOrder].item.push(newItem);
    }

    if (deleteOld && questionId) {
      await this.removeOldProducts(item, questionId);
    }

    if (questionId) {
      const itemPropertyData = JSON.stringify({
        questionId: Number(questionId),
        productId: Number(prod.id),
      });
      this.addItemProperty(item, ItemPropertyType.ProductQuestionRef, itemPropertyData);
    }

    if (once) {
      if (
        item.product.find((p) => p.properties.find(
          (i) => i.type == ItemPropertyType.Product && i.value == prod.id,
        ))
      ) {
        return;
      }
    }
    if (!qty) {
      qty = 1;
    }

    // Deep copy product
    const product: Product = JSON.parse(
      JSON.stringify(prod),
    ) as Product;

    this.addItemProperty(item, ItemPropertyType.Product, product.id.toString());
    this.addItemProduct(item, product);

    // add checklist to product from properties if not exists
    for (let i = 0; i < product.properties.length; i++) {
      const prop = product.properties[i];
      if (prop.type == ProductPropertyType.Checklist) {
        const checklist = window.Data.checklist.find((c) => c.id === parseInt(prop.value.toString() || ''));
        if (checklist && checklist.id) {
          if (!product.checklist) {
            product.checklist = [];
          }
          const existingChecklist = product.checklist.find((c) => c.id === checklist?.id);
          if (!existingChecklist) {
            product.checklist.push(JSON.parse(JSON.stringify(checklist)));
            for (let j = 0; j < window.Data.question.length; j++) {
              const q = window.Data.question[j];
              if (q.parent_id === checklist.id && !checklist.question.find((c) => c.id === q.id)) {
                checklist.question.push(JSON.parse(JSON.stringify(q)));
              }
            }
          }
        }
      }
    }

    // get product with name productline and add to item
    let productline = JSON.parse(JSON.stringify(window.Data.product.find((p) => p.properties.find((i) => i.value.toString().match('ProductLine'))))) as Product;
    const ERPprodid = product.properties.find((i) => i.type == ProductPropertyType.ERPprodid) || product.properties.find((i) => i.type == ProductPropertyType.MRP24SOProdid);
    if (productline && ERPprodid) {
      // check if product with name productline exists on item and if not add it
      const itemproductline = item.product.find((p) => p.properties.find((i) => i.value.toString().match('ProductLine')));
      if (!itemproductline) {
        this.addItemProduct(item, productline);
      } else {
        productline = itemproductline;
      }

      // add productline properties to item if not exists
      if (!item.properties.find((i) => i.type == ItemPropertyType.Product && i.value == productline.id)) {
        this.addItemProperty(item, ItemPropertyType.Product, productline.id.toString());
      }
      // add checklists to productline
      for (let i = 0; i < productline.properties.length; i++) {
        const prop = productline.properties[i];
        if (prop.type == ProductPropertyType.Checklist) {
          const checklist = productline.checklist.find((c) => c.id === parseInt(prop.value.toString() || ''));
          if (checklist) {
            if (!productline.checklist) {
              productline.checklist = [];
            }

            const q = JSON.parse(JSON.stringify(window.Data.question.find((q) => q.properties.find((p) => p.type == QuestionPropertyType.Name && p.value == 'ProductLine'))));
            checklist.question.push(q);
            if (!q) {
              throw new Error('ProductLine not found in checklist');
            }
            // get max sequence number of question
            const maxSequenceNumber = this.getMaxSequenceNumber(q);
            if (maxSequenceNumber != 0) {
              const prop = q.properties.find((p) => p.type == QuestionPropertyType.SequenceNumber);
              if (prop) {
                prop.value = maxSequenceNumber + 1;
                q._seqno = maxSequenceNumber + 1;
              }
            }

            const now = new Date(Date.now()) as unknown;
            if (!q.answer) {
              q.answer = new CustomArray<Answer>();
            }

            const q_property: Property = JSON.parse(JSON.stringify(q.properties));

            const localAnswer = {
              id: 0,
              parent_id: q.id,
              item_id: (item as Item).id,
              created: Math.floor(now as number / 1000),
              seq_no: q._seqno || q.properties.find((p) => p.type == QuestionPropertyType.SequenceNumber)?.value as number * 1,
              checklist_id: checklist.id,
              properties: new CustomArray<Property>(
                {
                  id: 0,
                  parent_id: 0,
                  type: AnswerPropertyType.Answer,
                  value: `{"id": ${product.id}, "qty": ${qty} }`,
                },
                {
                  id: 0,
                  parent_id: 0,
                  type: AnswerPropertyType.AnsweredBy,
                  value:
                    window.activeUser.properties.find(
                      (p) => p.type == UserPropertyType.Email,
                    )?.value || '',
                },
                {
                  id: 0,
                  parent_id: 0,
                  type: AnswerPropertyType.CustomQuestionProperties,
                  value: JSON.stringify(q_property),
                },
              ),
            };
            localAnswer.id = this.getNextAvailableTemporaryID(localAnswer);
            q.answer.push(localAnswer);
          }
        }
      }
    }
  }

  async addItem(order: Order, item: Item, product: Product) {
    const itemProperty = {
      id: 0, parent_id: 0, type: ItemPropertyType.Product, value: product.id,
    } as Property;

    order.item.push({
      id: 0,
      parent_id: order.id,
      properties: new CustomArray<Property>(itemProperty),
      product: new CustomArray<Product>(product),
    });
    await this.renderall();
  }

  getQuestion(q: Dataset): Question | undefined {
    return window.Data.order
      .find((o) => o.id === parseInt(q.orderid, 10))
      ?.item.find((i) => i.id === parseInt(q.itemid, 10))
      ?.product.find((p) => p.id === parseInt(q.productid, 10))
      ?.checklist.find((c) => c.id === parseInt(q.checklistid, 10))
      ?.question.find((quest) => quest.id === parseInt(q.questionid, 10) && quest._seqno === parseInt(q.seqno, 10));
  }

  findQuestion(i: Item, q: string | number) {
    for (const product of i.product) {
      for (const checklist of product.checklist) {
        for (const question of checklist.question) {
          if (question.id === q || question.properties.find((p) => p.type === QuestionPropertyType.Name)?.value === q) {
            return question;
          }
        }
      }
    }
    return undefined;
  }

  getChecklist(q: Dataset | number | string): Checklist | undefined {
    if (typeof q === 'string') {
      // find checklist by name in window.Data.order[window.activeOrder]
      if (window.Data.order[window.activeOrder].item) {
        for (const item of window.Data.order[window.activeOrder].item) {
          for (const product of item.product) {
            if (product.checklist) {
              for (const checklist of product.checklist) {
                if (
                  checklist.properties.find((i) => i.type == ChecklistPropertyType.Name)
                    ?.value == q
                ) {
                  return checklist;
                }
              }
            }
          }
        }
      }
      return undefined;
    }
    if (typeof q === 'number') {
      // find checklist by name in window.Data.order[window.activeOrder]
      if (window.Data.order[window.activeOrder].item) {
        for (const item of window.Data.order[window.activeOrder].item) {
          for (const product of item.product) {
            if (product.checklist) {
              for (const checklist of product.checklist) {
                if (checklist.id === q) {
                  return checklist;
                }
              }
            }
          }
        }
      }
      return undefined;
    } return window.Data.order.find((o) => o.id == parseInt(q.orderid))
      ?.item.find((i) => i.id == parseInt(q.itemid))
      ?.product.find((p) => p.id == parseInt(q.productid))
      ?.checklist.find((c) => c.id == parseInt(q.checklistid));
  }

  getProduct(q: Dataset | number | string): Product | undefined {
    if (typeof q === 'string') {
      for (const product of window.Data.product) {
        if (
          product.properties.find((i) => i.type == ProductPropertyType.Name)?.value == q || product.properties.find((i) => i.type == ProductPropertyType.ERPprodid)?.value == q || product.properties.find((i) => i.type == ProductPropertyType.MRP24SOProdid)?.value == q
        ) {
          return product;
        }
      }
      return undefined;
    }
    if (typeof q === 'number') {
      for (const product of window.Data.product) {
        if (
          product.id == q
        ) {
          return product;
        }
      }
      return undefined;
    }
    return window.Data.order
      .find((o) => o.id == parseInt(q.orderid))
      ?.item.find((i) => i.id == parseInt(q.itemid))
      ?.product.find((p) => p.id == parseInt(q.productid)) || undefined;
  }

  getItem(q: Dataset): Item | undefined {
    console.log('getItem ', q.questionid);
    return window.Data.order.find((o) => o.id == parseInt(q.orderid))?.item.find((i) => i.id == parseInt(q.itemid));
  }

  getOrder(q: Dataset): Order | undefined {
    return window.Data.order.find((o) => o.id == parseInt(q.orderid));
  }

  getOrderQuestion(q: Question): Order | undefined {
    if (q._order_id) {
      return window.Data.order.find((o) => o.id === q._order_id);
    }
    if (q._itemid) {
      return window.Data.order.find((o) => (o.item ?? []).some((i) => i.id === q._itemid));
    }

    return undefined;
  }

  getOrderPropertyValue(orderId: string | number, type: OrderPropertyType, value?: Property['value']): Property['value'] | undefined {
    return window.Data.order.find((o) => o.id === Number(orderId))
      ?.properties.find((p) => p.type === type && (value ? value === p.value : true))?.value;
  }

  getActiveOrderPropertyValue(type: OrderPropertyType, value?: Property['value']): Property['value'] | undefined {
    return window.Data.order[window.activeOrder]?.properties.find((p) => p.type === type && (value ? value === p.value : true))?.value;
  }

  async duplicateActiveOrder(
    allData?: boolean,
  ): Promise<void> {
    if (window.activeOrder < 0 || window.activeOrder >= window.Data.order.length) {
      console.log('DuplicateOrder ERROR: ActiveOrder was out of range');
      return;
    }
    const currentOrder = window.Data.order[window.activeOrder];

    if (!currentOrder.item || !currentOrder.item.length) {
      console.log('DuplicateOrder ERROR: Order had no items');
      return;
    }

    if (currentOrder.properties.some((p) => p.type === OrderPropertyType.DisableDuplicate && p.value.toString() === '1')) {
      console.log('DuplicateOrder ERROR: Not possible to duplicate this order');
      const isNOR = window.activelanguage === 'NOR';
      const text = isNOR ? 'Ikke mulig å kopiere denne ordren, lag ny ordre.' : 'Not possible to duplicate this order, create a new order.';
      this.alertHandler(text);
      return;
    }

    console.log(window.Data.order[window.activeOrder]);
    const tempOrder = structuredClone(currentOrder);

    // Reset readonly property on questions
    for (let i = 0; i < tempOrder.item.length; i++) {
      const item = tempOrder.item[i];
      for (let j = 0; j < item.product.length; j++) {
        const product = item.product[j];
        for (let k = 0; k < product.checklist.length; k++) {
          const checklist = product.checklist[k];
          for (let l = 0; l < checklist.question.length; l++) {
            const question = checklist.question[l];
            for (let m = 0; m < question.properties.length; m++) {
              const prop = question.properties[m];
              if (prop.type === QuestionPropertyType.WriteProtected && prop.id === 1 && prop.value === true.toString()) {
                // delete prop
                question.properties.splice(m, 1);
                m -= 1;
              }
            }
          }
        }
      }
    }

    // Remove any Opprett ordre answer from questions
    // for (let i = 0; i < tempOrder.item.length; i++) {
    //   const item = tempOrder.item[i];
    //   for (let j = 0; j < item.product.length; j++) {
    //     const product = item.product[j];
    //     for (let k = 0; k < product.checklist.length; k++) {
    //       const checklist = product.checklist[k];
    //       for (let l = 0; l < checklist.question.length; l++) {
    //         const question = checklist.question[l];
    //         if (question.id === 1066) {
    //           if (question.answer) {
    //             for (let m = 0; m < question.answer.length; m++) {
    //               const answer = question.answer[m];
    //               if (answer) {
    //                 question.answer.splice(m, 1);
    //                 m -= 1;
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // Reset order
    tempOrder.id = 0;
    for (let i = 0; i < tempOrder.properties.length; i++) {
      const prop = tempOrder.properties[i];
      prop.id = 0;
      prop.parent_id = 0;
    }
    tempOrder.id = this.getNextAvailableTemporaryID(tempOrder);
    console.log(window.Data.order[window.activeOrder]);

    // Set new name on the order
    const nameProperty = tempOrder.properties.find((p) => p.type === OrderPropertyType.Name);
    if (nameProperty) {
      nameProperty.value = `${window.activeUser.properties.find((p) => p.type === UserPropertyType.Email)?.value?.toString() ?? ''}${nowString()}`;
    }
    // allData = true copy everything (with answers)
    // allData = false copy only the first item (customerdata) with answers, copy the second item (newsales?) and do NOT copy the third+ item(s)

    if (!allData) {
      // The NewSales item is the last item we shall copy, remove every item after this:
      const newSalesItemIndex = tempOrder.item.findIndex((i) => !!i.product.find((p) => p.properties.find((p) => p.type === ProductPropertyType.Name)?.value === 'NewSales' || p.id === 1609));
      const nextIndex = newSalesItemIndex + 1;
      if (newSalesItemIndex > -1 && tempOrder.item.length > nextIndex) {
        tempOrder.item.splice(nextIndex, tempOrder.item.length - nextIndex);
      }
    }

    for (let i = 0; i < tempOrder.item.length; i++) {
      const item = tempOrder.item[i];
      item.id = 0;
      item.parent_id = 0;
      for (let j = 0; j < item.properties.length; j++) {
        const prop = item.properties[j];
        prop.id = 0;
        prop.parent_id = 0;
      }
      for (let j = 0; j < item.product.length; j++) {
        const p = item.product[j];
        for (let k = 0; k < p.checklist.length; k++) {
          const c = p.checklist[k];
          if (c.question) {
            for (let l = 0; l < c.question.length; l++) {
              const q = c.question[l];
              if (q.id === 1027 || q.id === 1028 || q.id === 1029) {
                q.answer = new CustomArray<Answer>();
              } else if (!allData && (p.properties.find((prop) => prop.type === ProductPropertyType.Name)?.value === 'NewSales' || p.id === 1609)) {
                q.answer = new CustomArray<Answer>();
              } else if (q.answer) {
                for (let m = 0; m < q.answer.length; m++) {
                  const answer = q.answer[m];
                  answer.id = 0;
                  answer.parent_id = 0;

                  for (let n = 0; n < answer.properties.length; n++) {
                    const answerProp = answer.properties[n];
                    answerProp.id = 0;
                    answerProp.parent_id = 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    // remove the old order?
    window.Data.order.push(tempOrder);
    window.activeOrder = window.Data.order.length - 1;
    await setData('OrderID', JSON.stringify(window.activeOrder));
    await setData('Order', JSON.stringify(window.Data.order));
    await this.syncData();
    await this.renderall();
  }

  setProperty(item: Order | Item | Answer, propType: number, value: string) {
    for (const prop of item.properties) {
      if (prop.type === propType) {
        prop.value = value;
        prop._updated = true;
        return;
      }
    }
    item.properties.push({
      id: 0,
      parent_id: item.id,
      type: propType,
      value,
    });
  }

  async newOrder(
    baseProduct: Product | string | number | undefined | (Product | string | number)[],
    customerdata?: Order,
  ): Promise<Order | undefined> {
    const newOrder: Order = {
      id: 0,
      properties: new CustomArray<Property>(),
      item: new CustomArray<Item>(),
    };
    newOrder.id = this.getNextAvailableTemporaryID(newOrder);

    const newOrderProperty: Property = {
      id: 0,
      parent_id: 0,
      type: OrderPropertyType.Name,
      value:
        (window.activeUser.properties.find((p) => p.type === UserPropertyType.Email)
          ?.value || '') + nowString(),
    };

    const baseArray: (string | number | Product | undefined)[] = [];
    if (!Array.isArray(baseProduct)) {
      baseArray.push(baseProduct);
    } else {
      baseArray.push(...baseProduct);
    }

    for (const product of baseArray) {
      let finalProduct: Product | undefined = undefined;
      switch (typeof product) {
        case 'string':
          finalProduct = window.Data.product.find((p) => p.properties.find((i) => i.type == ProductPropertyType.Name && i.value == product));
          break;
        case 'number':
          finalProduct = window.Data.product.find((p) => p.properties.find((i) => i.type == ProductPropertyType.ERPprodid && i.value == product));
          break;
        default:
          break;
      }
      if (!finalProduct) {
        finalProduct = window.Data.product.find((p) => p.properties.find((i) => i.type == ProductPropertyType.MRP24SOProdid && i.value == product));
      }

      if (!finalProduct) {
        return undefined;
      }

      const prod = JSON.parse(JSON.stringify(finalProduct)) as Product;
      prod.checklist = [];
      for (let i = 0; i < prod.properties.length; i++) {
        const prop = prod.properties[i];
        if (prop.type == ProductPropertyType.Checklist) {
          const checklist = window.Data.checklist.find((c) => c.id == prop.value);
          if (checklist) {
            const newChecklist = JSON.parse(JSON.stringify(checklist));
            prod.checklist.push(newChecklist);
          }
        }
      }

      const productProperty = {
        id: 0,
        parent_id: 0,
        type: ItemPropertyType.Product,
        value: prod.id,
      };

      newOrder.item.push({
        id: 0,
        parent_id: newOrder.id,
        properties: new CustomArray<Property>(productProperty),
        product: new CustomArray<Product>(prod),
      });

      // if customerdata is set, copy answers from checklist CustomerData to new order
      if (customerdata && customerdata.item) {
        for (const item of customerdata.item) {
          for (const product of item.product) {
            for (const checklist of product.checklist) {
              if (checklist.properties.find((i) => i.type == ChecklistPropertyType.Name)?.value == 'Customerdata') {
                for (const question of checklist.question) {
                  if (question.answer) {
                    // get latest answer
                    const _res: Answer[] = question.answer.sort(utils.sortAnswers);
                    const answer = _res[0];
                    if (answer) {
                      const newAnswer = JSON.parse(JSON.stringify(answer)) as Answer;
                      newAnswer.id = 0;
                      newAnswer.parent_id = 0;
                      newAnswer.item_id = 0;
                      for (const prop of newAnswer.properties) {
                        prop.id = 0;
                        prop.parent_id = 0;
                      }
                      newOrder.item[newOrder.item.length - 1].product[0].checklist[0].question.find((q) => q.id === question.id)?.answer?.push(newAnswer);
                    }
                  }
                }
              }
            }
          }
        }
      }
      closeMenu();
    }

    newOrder.item[0].id = this.getNextAvailableTemporaryID(newOrder.item[0]);

    window.Data.order.push(newOrder);
    window.activeOrder = window.Data.order.length - 1;
    newOrder.properties.push(newOrderProperty);
    await setData('OrderID', JSON.stringify(window.activeOrder));
    await this.renderall();
    return newOrder;
  }

  showOnlyInstance(question: Question): void {
    const instance = this.instance(question);
    const orderID = question._order_id || 0;

    for (let i = 0; i < window.Data.order[orderID].item.length; i++) {
      const item = window.Data.order[orderID].item[i];
      for (let j = 0; j < item.product.length; j++) {
        const product = item.product[j];
        for (let k = 0; k < product.checklist.length; k++) {
          const checklist = product.checklist[k];
          if (checklist.question) {
            for (let l = 0; l < checklist.question.length; l++) {
              const q = checklist.question[l];
              const qtype = parseInt(
                (q.properties.find((i) => i.type == QuestionPropertyType.Type)
                  ?.value as string) || '0',
              );
              if (
                qtype == QuestionType.Groupheading
                && instance != question._itemid
              ) {
                let seq_no = 0;
                const value = question.properties.find((p) => p.type == QuestionPropertyType.SequenceNumber)?.value;
                if (value) {
                  const seqNo = parseInt(value.toString());
                  if (seqNo) {
                    seq_no = seqNo;
                  } else {
                    console.error(`Sequence number not found for question >${question.id}<`);
                  }
                }
                const q2: Dataset = {
                  orderid: q._order_id?.toString() || '',
                  itemid: q._itemid?.toString() || '',
                  productid: q._product?.toString() || '',
                  checklistid: q._checklistid?.toString() || '',
                  checklist: q._checklist?.toString() || '',
                  questionid: q.id.toString(),
                  seqno: seq_no.toString(),
                };
                const utils = new ChecklistUtils();
                utils.updateAnswer({ dataset: q2 }, '1');
              }
            }
          }
        }
      }
    }
  }

  confirmDeleteHeader(dataset: Dataset) {
    // show checkbox with ID deletecheck inside dataset id
    const inputs = window.document.getElementsByTagName('input');
    // loop through all inputs and set dataset.id to value of input
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i] as HTMLInputElement;
      if (input.id == 'deletecheck' && input.dataset.itemid == dataset.itemid) {
        if (input.parentElement) {
          input.parentElement.style.visibility = '';
        }
      }
    }
  }

  async deleteGroup(dataset: Dataset) {
    const orderID = parseInt(dataset.orderid) || 0;
    const itemID = dataset.itemid || 0;
    const order = window.Data.order.find((o) => o.id == orderID);
    if (!order) {
      return;
    }
    const item = order.item.find((i) => i.id == itemID);
    if (item) {
      item._delete = true;
      utils.syncData();
    }
    await this.renderall();
  }

  highlight(element: HTMLElement) {
    element.style.border = '8px';
    element.style.borderStyle = 'solid';
    element.style.borderColor = 'red';
  }

  signPad: SignaturePad | undefined;

  signaturePad(element: HTMLElement) {
    if (element.parentElement) {
      const canvas = element.parentElement.getElementsByTagName(
        'canvas',
      )[0] as HTMLCanvasElement;
      this.signPad = new SignaturePad(canvas);
      canvas.style.display = 'block';
      const canvasContext = canvas.getContext('2d');
      if (canvasContext) {
        canvasContext.fillStyle = '#fff';
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);
        canvasContext.beginPath();
        canvasContext.lineWidth = this.signPad.minWidth;
        canvasContext.strokeStyle = '#ccc';
        canvasContext.moveTo(1, canvas.height * 0.75);
        canvasContext.lineTo(canvas.width - 1, canvas.height * 0.75);
        canvasContext.stroke();
        canvasContext.closePath();
      }

      window.utils.holdRendering = true;
    }
  }

  signatureOK() {
    return JSON.stringify(this.signPad?.toData()).length > 1000;
  }

  signatureJSON(data?: string) {
    if (data) {
      try {
        this.signPad?.fromData(JSON.parse(data));
      } catch (e) {
        logEntry(`${JSON.stringify(e)} ${e}`);
        console.log(e);
      }
    }
    return this.signPad?.toData();
  }

  drawSignature(data: string): string {
    let paths: SignaturePath[];
    try {
      paths = JSON.parse(data) as SignaturePath[];
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      return '';
    }
    const canv = document.createElement('canvas');
    canv.height = 105;
    canv.width = window.innerWidth - 32;
    const context = canv.getContext('2d');
    if (!context) {
      return '';
    }
    context.lineCap = 'round';
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.lineWidth = 2;
    context.strokeStyle = '#145394';
    for (const stroke of paths) {
      if (typeof stroke === 'object') {
        if (stroke.mx > canv.width) {
          canv.width = stroke.mx + 10;
        }
        if (stroke.lx > canv.width) {
          canv.width = stroke.lx + 10;
        }
      }
    }

    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canv.width, canv.height);

    // Draw line for signature
    context.strokeStyle = '#cccccc';
    context.beginPath();
    context.moveTo(0, 85);
    context.lineTo(canv.width, 85);
    context.stroke();
    context.closePath();

    context.strokeStyle = '#145394';
    for (const stroke of paths) {
      if (typeof stroke === 'object') {
        context.beginPath();
        context.moveTo(stroke.mx, stroke.my);
        context.lineTo(stroke.lx, stroke.ly);
        context.stroke();
        context.closePath();
      }
    }
    const img = canv.toDataURL('image/jpeg', 0.7);
    return img;
  }

  getAnswer(questionid: number | string | Question, i?: Item | number): string | number {
    let localAnswer: string | number | undefined = '';
    if (i && typeof i !== 'number') {
      for (const prod of i.product) {
        if (prod.checklist) {
          for (const checklist of prod.checklist) {
            if (checklist.question) {
              for (const question of checklist.question) {
                switch (typeof questionid) {
                  case 'number':
                    // look for question id
                    if (questionid && question.id == questionid) {
                      localAnswer = question.answer?.sort(this.sortAnswers)[0]?.properties.find((i) => i.type == AnswerPropertyType.Answer)?.value;
                      if (this.isNumeric(localAnswer as string)) {
                        return parseInt(localAnswer as string);
                      }
                      return localAnswer || '';
                    }
                    break;
                  case 'string':
                    // look for question name in question.properties
                    if (questionid && question.properties.find((i) => i.type == QuestionPropertyType.Name)?.value == questionid) {
                      localAnswer = question.answer?.sort(this.sortAnswers)[0]?.properties.find((i) => i.type == AnswerPropertyType.Answer)?.value;
                      if (this.isNumeric(localAnswer as string)) {
                        return parseInt(localAnswer as string);
                      }
                      return localAnswer || '';
                    }
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
      }
    } else if (i && typeof i === 'number') {
      if (window.Data.order && window.Data.order[window.activeOrder] && window.Data.order[window.activeOrder].item) {
        for (const item of window.Data.order[window.activeOrder].item) {
          if (item.id == i) {
            for (const prod of item.product) {
              if (prod.checklist) {
                for (const checklist of prod.checklist) {
                  if (checklist.question) {
                    for (const question of checklist.question) {
                      switch (typeof questionid) {
                        case 'number':
                          // look for question id
                          if (questionid && question.id == questionid) {
                            localAnswer = question.answer?.sort(this.sortAnswers)[0]?.properties
                              .find((i) => i.type == AnswerPropertyType.Answer)?.value;
                            if (this.isNumeric(localAnswer as string)) {
                              return parseInt(localAnswer as string);
                            }
                            return localAnswer || '';
                          }
                          break;
                        case 'string':
                          // look for question name in question.properties
                          if (questionid && question.properties.find((i) => i.type == QuestionPropertyType.Name)?.value === questionid) {
                            localAnswer = question.answer?.sort(this.sortAnswers)[0]?.properties
                              .find((i) => i.type == AnswerPropertyType.Answer)?.value;
                            if (this.isNumeric(localAnswer as string)) {
                              return parseInt(localAnswer as string);
                            }
                            return localAnswer || '';
                          }
                          break;
                        default:
                          break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else if (window.Data.order && window.Data.order[window.activeOrder] && window.Data.order[window.activeOrder].item) {
      for (const item of window.Data.order[window.activeOrder].item) {
        for (const prod of item.product) {
          if (prod.checklist) {
            for (const checklist of prod.checklist) {
              if (checklist.question) {
                for (const question of checklist.question) {
                  switch (typeof questionid) {
                    case 'object':
                      // use question
                      localAnswer = questionid.answer?.sort(this.sortAnswers)[0]?.properties.find((i) => i.type == AnswerPropertyType.Answer)?.value;
                      if (this.isNumeric(localAnswer as string)) {
                        return parseInt(localAnswer as string);
                      }
                      return localAnswer || '';

                      break;
                    case 'number':
                      // look for question id
                      if (questionid && question.id == questionid) {
                        localAnswer = question.answer?.sort(this.sortAnswers)[0]?.properties.find((i) => i.type == AnswerPropertyType.Answer)?.value;
                        if (this.isNumeric(localAnswer as string)) {
                          return parseInt(localAnswer as string);
                        }
                        return localAnswer || '';
                      }
                      break;
                    case 'string':
                      // look for question name in question.properties
                      if (questionid && question.properties.find((i) => i.type == QuestionPropertyType.Name)?.value == questionid) {
                        localAnswer = question.answer?.sort(this.sortAnswers)[0]?.properties.find((i) => i.type == AnswerPropertyType.Answer)?.value;
                        if (this.isNumeric(localAnswer as string)) {
                          return parseInt(localAnswer as string);
                        }
                        return localAnswer || '';
                      }
                      break;
                    default:
                      break;
                  }
                }
              }
            }
          }
        }
      }
    }
    return '';
  }

  getAnswerObject(questionid: number | string): Answer | undefined {
    if (window.Data.order && window.Data.order[window.activeOrder] && window.Data.order[window.activeOrder].item) {
      for (const item of window.Data.order[window.activeOrder].item) {
        for (const prod of item.product) {
          if (prod.checklist) {
            for (const checklist of prod.checklist) {
              if (checklist.question) {
                for (const question of checklist.question) {
                  switch (typeof questionid) {
                    case 'number':
                      // look for question id
                      if (questionid && question.id == questionid) {
                        return question.answer?.sort(this.sortAnswers)[0];
                      }
                      break;
                    case 'string':
                      // look for question name in question.properties
                      if (questionid && question.properties.find((i) => i.type === QuestionPropertyType.Name)?.value === questionid) {
                        return question.answer?.sort(this.sortAnswers)[0];
                      }
                      break;
                    default:
                      return undefined;
                  }
                }
              }
            }
          }
        }
      }
    }
    return undefined;
  }

  updateQuestionAnswer(questionid: string | number, answer: string | number) {
    let _question: Question | undefined;
    let _item: Item | undefined;
    let _checklist: Checklist | undefined;
    if (window.Data.order && window.Data.order[window.activeOrder]) {
      for (const item of window.Data.order[window.activeOrder].item ?? []) {
        for (const prod of item.product ?? []) {
          for (const checklist of prod.checklist ?? []) {
            for (const question of checklist.question ?? []) {
              switch (typeof questionid) {
                case 'number':
                  // look for question id
                  if (questionid && question.id == questionid) {
                    _question = question;
                    _item = item;
                    _checklist = checklist;
                  }
                  break;
                case 'string':
                  // look for question name in question.properties
                  if (questionid && question.properties.find((i) => i.type == QuestionPropertyType.Name)?.value == questionid) {
                    _question = question;
                    _item = item;
                    _checklist = checklist;
                  }
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    }

    if (_question && _item && _checklist) {
      const now = new Date(Date.now()) as unknown;
      const localAnswer: Answer = {
        id: 0,
        created: Math.floor(now as number / 1000),
        parent_id: _question.id,
        item_id: _item.id,
        checklist_id: _checklist.id,
        seq_no: _question._seqno,
        properties: new CustomArray<Property>(
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.Answer,
            value: answer,
          },
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.AnsweredBy,
            value:
              window.activeUser.properties.find(
                (p) => p.type == UserPropertyType.Email,
              )?.value || '',
          },
        ),
      };
      _question.answer = new CustomArray<Answer>(localAnswer);
    }
  }

  getData(questionid: number | string, item?: Item): Property[] {
    if (item) {
      for (const prod of item.product) {
        if (prod.checklist) {
          for (const checklist of prod.checklist) {
            for (const question of checklist.question) {
              switch (typeof questionid) {
                case 'number':
                  // look for question id
                  if (questionid && question.id == questionid) {
                    return question.properties.filter((i) => i.type == QuestionPropertyType.Data) || [];
                  }
                  break;
                case 'string':
                  // look for question name in question.properties
                  if (questionid && question.properties.find((i) => i.type == QuestionPropertyType.Name)?.value == questionid) {
                    return question.properties.filter((i) => i.type == QuestionPropertyType.Data) || [];
                  }
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    } else {
      for (const item of window.Data.order[window.activeOrder].item) {
        for (const prod of item.product) {
          if (prod.checklist) {
            for (const checklist of prod.checklist) {
              for (const question of checklist.question) {
                switch (typeof questionid) {
                  case 'number':
                    // look for question id
                    if (questionid && question.id == questionid) {
                      return question.properties.filter((i) => i.type == QuestionPropertyType.Data) || [];
                    }
                    break;
                  case 'string':
                    // look for question name in question.properties
                    if (questionid && question.properties.find((i) => i.type == QuestionPropertyType.Name)?.value == questionid) {
                      return question.properties.filter((i) => i.type == QuestionPropertyType.Data) || [];
                    }
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
      }
    }
    return [];
  }

  insertQuotes(question: Question, filter?: string, showAll?: boolean, exclude?: string): void {
    const properties: Property[] = [];

    const activeUserEmail = window.activeUser.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString() || 'Not found';

    const otherQuotes: Order[] = [];

    // Current Users orders first
    const userQuotes = window.Data.quotes.map((order) => {
      let name = order.properties.find((i) => i.type === OrderPropertyType.Name)?.value.toString() || '';
      // Remove last 14 digits (date) from name
      name = name.substring(0, name.length - 14);
      const statuses = order.properties.filter((i) => i.type === OrderPropertyType.Status);
      const match = statuses.some((s) => s.value.toString().match(filter ?? '.+'));
      const skip = exclude ? statuses.some((s) => s.value.toString().match(exclude)) : false;

      if (!skip && match) {
        if (name.match(activeUserEmail)) {
          return order;
        }
        otherQuotes.push(order);
        return null;
      }
      return null;
    }).filter(removeNullsAndUndefined);

    userQuotes.sort((a, b) => {
      const aname = a.properties.find((i) => i.type == OrderPropertyType.Name)?.value.toString() || '';
      const bname = b.properties.find((i) => i.type == OrderPropertyType.Name)?.value.toString() || '';
      return aname.localeCompare(bname) * -1;
    });

    if (showAll) {
      otherQuotes.sort((a, b) => {
        const aname = a.properties.find((i) => i.type == OrderPropertyType.Name)?.value.toString() || '';
        const bname = b.properties.find((i) => i.type == OrderPropertyType.Name)?.value.toString() || '';
        return aname.localeCompare(bname) * -1;
      });
    }

    let currentuseremail = activeUserEmail;

    for (const prop of question.properties) {
      if (prop.type != QuestionPropertyType.Data) {
        properties.push(prop);
      }
    }

    const allQuotes = showAll ? userQuotes.concat(otherQuotes) : userQuotes;

    for (const order of allQuotes) {
      let displayname = order.properties.find((p) => p.type == OrderPropertyType.Name)?.value.toString().trim() || '';
      const date = displayname.substring(displayname.length - 10).replace(/^(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, '$2/$1 $3:$4');
      const orderUser = displayname.substring(0, displayname.length - 14);

      const orderCustomerName = order.properties.find((p) => p.type == OrderPropertyType.CustomerName)?.value.toString().trim();
      if (orderCustomerName) {
        displayname = orderCustomerName;
      }

      const orderSummary = order.properties.find((p) => p.type == OrderPropertyType.Summary)?.value.toString().trim();
      if (orderSummary) {
        displayname = orderSummary;
      }

      if (currentuseremail != orderUser) {
        currentuseremail = orderUser;
        properties.push({
          id: 1,
          type: QuestionPropertyType.Data,
          value: `{"Group":{"NOR":"${orderUser}"}}`,
          parent_id: question.id,
        });
      }

      const newprop: Property = {
        id: 1,
        type: QuestionPropertyType.Data,
        value: `{"Item":{"NOR":"${date} ${displayname}","data":${order.id}}}`,
        parent_id: question.id,
      };

      properties.push(newprop);
    }

    question.properties = properties;
  }

  insertUsers(question: Question): void {
    const { users } = window.Data;

    if (!users?.length) {
      return;
    }

    const sortedUsers = users
      .map((user) => user.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString())
      .filter(removeNullsAndUndefined);

    sortedUsers.sort((a, b) => a.localeCompare(b));

    const properties: Property[] = sortedUsers.map((user) => ({
      id: 1,
      type: QuestionPropertyType.Data,
      value: `{"Item":{"NOR":"${user}", "data":"${user}"}}`,
      parent_id: question.id,
    }));

    const questionProperties = question.properties.filter((p) => p.type !== QuestionPropertyType.Data);

    question.properties = [...questionProperties, ...properties];

    const activeUserEmail = window.activeUser.properties.find((p) => p.type == UserPropertyType.Email)?.value.toString();

    if (activeUserEmail && !question.answer) {
      question.answer = new CustomArray<Answer>(
        {
          id: 0,
          checklist_id: question._checklistid as number,
          item_id: question._itemid as number,
          created: Math.floor(new Date() as unknown as number / 1000),
          parent_id: question.id,
          seq_no: question._seqno,
          properties: new CustomArray<Property>(
            {
              id: 0,
              parent_id: 0,
              type: AnswerPropertyType.Answer,
              value: activeUserEmail,
            },
          ),
        },
      );
    }
  }

  checkMatch(type: RegExp | string[], valueAsString: string | null): boolean {
    if (!valueAsString || valueAsString.trim() === '') {
        return false;
    }
    if (type instanceof RegExp) {
        return type.test(valueAsString);
    }
    if (Array.isArray(type)) {
        return type.includes(valueAsString);
    }
    return false;
}

  insertProducts(type: RegExp | string[] | string, question: Question, filter?: string, removeMulti?: boolean, subCategory?: RegExp | string[] | string): void {
    const properties: Property[] = [];
    const KampanjeProduct: Product[] = [];
    const FujitsuProduct: Product[] = [];
    const OtherProducts: Product[] = [];
    let sortedProducts: Product[] = [];

    let subCat: RegExp | string[];
    if (typeof subCategory === 'string') subCat = [subCategory];
    else if (!subCategory) subCat = [];
    else subCat = subCategory;

    for (const prop of question.properties) {
      if (prop.type != QuestionPropertyType.Data) {
        properties.push(prop);
      }
    }

    if (!filter) {
      filter = '.+';
    }

    const regExFilter = new RegExp(filter, 'i');

    if (typeof type === 'string') {
      type = [type];
    } else if (!type) {
      type = [];
    }
    // Kampanje produkt
    for (let i = 0; i < window.Data.product.length; i++) {
      const prod = window.Data.product[i];
      const prodname = prod.properties.find((i) => i.type == ProductPropertyType.Name)?.value.toString() || '';
      if (prodname.match(/^KAMPANJE/) && prodname.match(regExFilter)) {
        const matchesFilter = regExFilter.test(prodname);
        if ((removeMulti && !prodname.toLowerCase().includes('multi')) || (!removeMulti && matchesFilter)) {
          KampanjeProduct.push(prod);
        }
      }
    }
    KampanjeProduct.sort((a, b) => {
      const aname = a.properties.find((i) => i.type == ProductPropertyType.Name)?.value.toString() || '';
      const bname = b.properties.find((i) => i.type == ProductPropertyType.Name)?.value.toString() || '';
      return aname.localeCompare(bname);
    });
    // Fujitsu produkt
    for (let i = 0; i < window.Data.product.length; i++) {
      const prod = window.Data.product[i];

      const prodname = prod.properties.find((i) => i.type == ProductPropertyType.Name)?.value.toString() || '';
      if (!prodname.match(/^KAMPANJE/) && prodname.toLowerCase().match(/fujitsu/) && prodname.match(regExFilter)) {
        const matchesFilter = regExFilter.test(prodname);
        if ((removeMulti && !prodname.toLowerCase().includes('multi')) || (!removeMulti && matchesFilter)) {
          FujitsuProduct.push(prod);
        }
      }
    }
    FujitsuProduct.sort((a, b) => {
      const aname = a.properties.find((i) => i.type == ProductPropertyType.Name)?.value.toString() || '';
      const bname = b.properties.find((i) => i.type == ProductPropertyType.Name)?.value.toString() || '';
      return aname.localeCompare(bname);
    });
    // Alle andre produkt
    for (let i = 0; i < window.Data.product.length; i++) {
      const prod = window.Data.product[i];
      const prodProperty = prod.properties.find((item) => item.type === ProductPropertyType.Name);
      const prodnameRaw = prodProperty?.value.toString() || '';
      const prodname = prodnameRaw.toLowerCase();
      const isNotCampaign = !prodname.startsWith('kampanje');
      const doesNotContainFujitsu = !prodname.includes('fujitsu');
      const matchesFilter = regExFilter.test(prodname);
      if (isNotCampaign && doesNotContainFujitsu && matchesFilter) {
        const includesMulti = prodname.toLowerCase().includes('multi');
        if ((removeMulti && !includesMulti) || !removeMulti) {
          OtherProducts.push(prod);
        }
      }
    }

    OtherProducts.sort((a, b) => {
      const aname = a.properties.find((i) => i.type == ProductPropertyType.Name)?.value.toString() || '';
      const bname = b.properties.find((i) => i.type == ProductPropertyType.Name)?.value.toString() || '';
      return aname.localeCompare(bname);
    });

    sortedProducts = KampanjeProduct.concat(FujitsuProduct, OtherProducts);

    for (const prod of sortedProducts) {
      const subCategoryProperty = prod.properties.find((p) => p.type === ProductPropertyType.SubCategory);
      // True if "subCategory" filter is defined and has subcategory property and has a match on that value, otherwise false
      // Also True if no subCategory is defined
      const subCategoryMatch = !subCategory ? true : !!subCategoryProperty && (this.checkMatch(subCat, subCategoryProperty.value.toString())
        || (Array.isArray(subCat) && subCat.length === 0));
      for (const prop of prod.properties) {
        const deliveryTime = prod.properties.find((p) => p.type == 16)?.value.toString() || '';
        const stocklevel = (prod.properties.find((p) => p.type == ProductPropertyType.Stocklevel)?.value as number) * 1;
        if (subCategoryMatch && prop.type == ProductPropertyType.ProductType && (this.checkMatch(type, prop.value.toString()) || (Array.isArray(type) && type.length === 0)) && !deliveryTime.match('181') && !(deliveryTime.match('181') && stocklevel == 0)) {
          const sumcost = ((prod.properties.find((p) => p.type == ProductPropertyType.PriceExVAT)?.value as number) * 1
            + (prod.properties.find((p) => p.type == ProductPropertyType.CostExVAT)?.value as number) * 1)
            * ((prod.properties.find((p) => p.type == ProductPropertyType.VAT)?.value as number || 25) + 100) / 100;
          let stocklevelText = '';
          if (stocklevel <= 4) {
            stocklevelText = '0 på lager';
          }
          if (stocklevel > 4 && stocklevel <= 25) {
            stocklevelText = '<25 på lager';
          }
          if (stocklevel > 25) {
            stocklevelText = '>25 på lager';
          }

          let displayname = prod.properties.find((p) => p.type == ProductPropertyType.Name)?.value.toString().trim() || '';

          displayname += ` ${stocklevelText}`;
          displayname += ` ( kr ${sumcost.toFixed(0)} )`;
          // make displayname JSON safe
          displayname = JSON.stringify(displayname);
          displayname = displayname.substring(1, displayname.length - 1);

          const newprop: Property = {
            id: 1,
            type: QuestionPropertyType.Data,
            value: `{"Item":{"NOR":"${displayname}","data":${prod.id}}}`,
            parent_id: question.id,
          };
          properties.push(newprop);
        }
      }
    }

    question.properties = properties;
  }

  getOrderSum(exDiscount?: boolean): number {
    let sum = 0;
    for (const item of window.Data.order[window.activeOrder].item) {
      if (item.product) {
        for (const prod of item.product) {
          // Is product prodline?
          if (prod.properties.find((p) => p.type == ProductPropertyType.Name)?.value.toString() == 'ProductLine') {
            if (prod.checklist) {
              for (const checlist of prod.checklist) {
                if (checlist.properties.find((p) => p.type == ChecklistPropertyType.Name)?.value.toString() == 'ProductLine') {
                  if (checlist.question) {
                    for (const question of checlist.question) {
                      if (question.properties.find((p) => p.type == QuestionPropertyType.Name)?.value.toString() == 'ProductLine') {
                        if (question.answer) {
                          const _data = this.getAnswer(question);
                          if (_data && (_data as string).length > 5) {
                            const data = JSON.parse(_data as string);
                            // {"id": 42, "qty": 1 , "discount" : 0}
                            if (data.id) {
                              const product = window.Data.product.find((p) => p.id == data.id);
                              if (product) {
                                const customProperty = product.properties.find((p) => p.type == ProductPropertyType.Custom);
                                if (customProperty) {
                                  try {
                                    const value = JSON.parse(customProperty.value.toString()) as ProductPropertyCustomValue;
                                    const price = value.price ? Number(this.getAnswer(value.price)) : 0;
                                    const discount = value.discount ? parseFloat(this.getAnswer(value.discount).toString()) : 0;
                                    if (!exDiscount && discount) {
                                      if (discount < 100) {
                                        sum += price * data.qty * (100 - discount) / 100;
                                      } else {
                                        sum += price * data.qty - discount;
                                      }
                                    } else {
                                      sum += price * data.qty;
                                    }
                                  } catch {
                                    console.log('Custom product property had an invalid json value');
                                  }
                                } else {
                                  const sumcost = (product.properties.find((p) => p.type == ProductPropertyType.PriceExVAT)?.value as number)
                                    * ((product.properties.find((p) => p.type == ProductPropertyType.VAT)?.value as number || 25) + 100) / 100;
                                  if (!exDiscount) {
                                    if (data.discount && parseFloat(data.discount) > 0) {
                                      if (parseFloat(data.discount) <= 100) {
                                        sum += sumcost * data.qty * (100 - parseFloat(data.discount)) / 100;
                                      } else {
                                        sum += sumcost * data.qty - parseFloat(data.discount);
                                      }
                                    } else {
                                      sum += sumcost * data.qty;
                                    }
                                  } else {
                                    sum += sumcost * data.qty;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // return rounded to 0 decimals
    return Math.round(sum * 100) / 100;
  }

  userGroup(group: string) {
    for (const property of window.activeUser.properties) {
      if (property.type === UserPropertyType.Group && property.value === group) {
        return true;
      }
    }
    return false;
  }

  getNextAvailableTemporaryID(item: Order | Item | Product | Checklist | Question | Answer): number {
    let id = -2;
    let type = '';
    if (Object.prototype.hasOwnProperty.call(item, 'item')) {
      type = 'order';
    }
    if (Object.prototype.hasOwnProperty.call(item, 'product')) {
      type = 'item';
    }
    if (Object.prototype.hasOwnProperty.call(item, 'checklist')) {
      type = 'product';
    }
    if (Object.prototype.hasOwnProperty.call(item, 'question')) {
      type = 'checklist';
    }
    if (Object.prototype.hasOwnProperty.call(item, 'answer')) {
      type = 'question';
    }
    if (Object.prototype.hasOwnProperty.call(item, 'item_id')) {
      type = 'answer';
    }

    switch (type) {
      case 'order':
        for (let i = 0; i < window.Data.order.length; i++) {
          if (window.Data.order[i].id == id) {
            id -= 1;
          }
        }
        break;
      case 'item':
        for (let i = 0; i < window.Data.order.length; i++) {
          if (window.Data.order[i].item) {
            for (let ii = 0; ii < window.Data.order[i].item.length; ii++) {
              if (window.Data.order[i].item[ii].id == id) {
                id -= 1;
              }
            }
          }
        }
        break;
      case 'product':
        for (let i = 0; i < window.Data.order.length; i++) {
          if (window.Data.order[i].item) {
            for (let ii = 0; ii < window.Data.order[i].item.length; ii++) {
              if (window.Data.order[i].item[ii].product) {
                for (let iii = 0; iii < window.Data.order[i].item[ii].product.length; iii++) {
                  if (window.Data.order[i].item[ii].product[iii].id == id) {
                    id -= 1;
                  }
                }
              }
            }
          }
        }
        break;
      case 'checklist':
        for (let i = 0; i < window.Data.order.length; i++) {
          if (window.Data.order[i].item) {
            for (let ii = 0; ii < window.Data.order[i].item.length; ii++) {
              if (window.Data.order[i].item[ii].product) {
                for (let iii = 0; iii < window.Data.order[i].item[ii].product.length; iii++) {
                  if (window.Data.order[i].item[ii].product[iii].checklist) {
                    for (let iiii = 0; iiii < window.Data.order[i].item[ii].product[iii].checklist.length; iiii++) {
                      if (window.Data.order[i].item[ii].product[iii].checklist[iiii].id == id) {
                        id -= 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        break;
      case 'question':
        for (let i = 0; i < window.Data.order.length; i++) {
          if (window.Data.order[i].item) {
            for (let ii = 0; ii < window.Data.order[i].item.length; ii++) {
              if (window.Data.order[i].item[ii].product) {
                for (let iii = 0; iii < window.Data.order[i].item[ii].product.length; iii++) {
                  if (window.Data.order[i].item[ii].product[iii].checklist) {
                    for (let iiii = 0; iiii < window.Data.order[i].item[ii].product[iii].checklist.length; iiii++) {
                      if (window.Data.order[i].item[ii].product[iii].checklist[iiii].question) {
                        for (let iiiii = 0; iiiii < window.Data.order[i].item[ii].product[iii].checklist[iiii].question.length; iiiii++) {
                          if (window.Data.order[i].item[ii].product[iii].checklist[iiii].question[iiiii].id == id) {
                            id -= 1;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        break;
      case 'answer':
        for (let i = 0; i < window.Data.order.length; i++) {
          if (window.Data.order[i].item) {
            for (let ii = 0; ii < window.Data.order[i].item.length; ii++) {
              if (window.Data.order[i].item[ii].product) {
                for (let iii = 0; iii < window.Data.order[i].item[ii].product.length; iii++) {
                  if (window.Data.order[i].item[ii].product[iii].checklist) {
                    for (let iiii = 0; iiii < window.Data.order[i].item[ii].product[iii].checklist.length; iiii++) {
                      if (window.Data.order[i].item[ii].product[iii].checklist[iiii].question) {
                        for (let iiiii = 0; iiiii < window.Data.order[i].item[ii].product[iii].checklist[iiii].question.length; iiiii++) {
                          if (window.Data.order[i].item[ii].product[iii].checklist[iiii].question[iiiii].answer) {
                            const answer = window.Data.order[i].item[ii].product[iii].checklist[iiii].question[iiiii].answer || [];
                            for (let iiiiii = 0; iiiiii < answer?.length || 0; iiiiii++) {
                              if (answer[iiiiii].id == id) {
                                id -= 1;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        break;
      default:
        throw new Error('getNextAvailableTemporaryID: unknown type');
    }
    return id;
  }

  public async resizeImage(dataUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        let scale = 1;
        if (img.width > maxImageSize || img.height > maxImageSize) {
          scale = Math.min(maxImageSize / img.width, maxImageSize / img.height);
        }
        const canvas = document.createElement('canvas');
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
        const resizedImage = canvas.toDataURL('image/jpeg');
        resolve(resizedImage);
      };
      img.onerror = (e) => {
        reject(e);
      };
      img.src = dataUrl;
    });
  }

  removeItemProperty(
    item: Item,
    type: ItemPropertyType,
    value: string,
  ) {
    item.properties.assign(item.properties.filter((p) => p.type !== type && p.value !== value));
  }

  addItemProperty(
    item: Item,
    type: ItemPropertyType,
    value: string,
  ) {
    if (item.properties.find((p) => p.type === type && p.value === value)) {
      return;
    }

    const property: Property = {
      id: 0,
      parent_id: item.id,
      type,
      value,
    };
    item.properties.push(property);
    // await this.renderall(); // Needed?
  }

  addItemProduct(
    item: Item,
    prod: Product,
  ) {
    if (item.product.find((p) => p.id === prod.id)) {
      return;
    }
    item.product.push(prod);
    // await this.renderall(); // Needed?
  }

  async removeOldProducts(item: Item | null, questionId: number | string): Promise<boolean> {
    if (!item) {
      return false;
    }
    // make sure item properties are of type CustomArray
    if (item.product) {
      for (const product of item.product) {
        if (product.checklist) {
          for (const checklist of product.checklist) {
            if (checklist.question) {
              for (const question of checklist.question) {
                if (question.answer) {
                  for (const answer of question.answer) {
                    answer.properties = new CustomArray<Property>(...answer.properties);
                  }
                  question.answer = new CustomArray<Answer>(...question.answer);
                }
              }
            }
          }
        }
      }
    }

    item.properties = new CustomArray<Property>(...item.properties);

    // Search through item properties after ItemPropertyType === ProductQuestionRef
    const propertiesToDelete: Property[] = [];
    for (const property of item.properties) {
      if (property.value && property.type === ItemPropertyType.ProductQuestionRef) {
        try {
          const value: {
            productId: string | number,
            questionId: string | number
          } = JSON.parse(property.value.toString());

          if (Number(value.questionId) === Number(questionId)) {
            // Finds only the first - assumes that there is only one product
            const index = item.properties.findIndex((p) => Number(p.value) === Number(value.productId));
            const productIndex = item.product.findIndex((p) => p.id === Number(value.productId));

            // TODO Could optionally check that the +1 product id is for a ProductLine?
            if (index !== -1) {
              propertiesToDelete.push(item.properties[index]);
              // remove one Productline from item.properties
              const prodlineID = window.Data.product.find((p) => p.properties.find((prop) => prop.type === ProductPropertyType.Name)?.value == 'ProductLine')?.id;
              const productLineIndex = item.properties.findIndex((p) => p.type === ItemPropertyType.Product && (p.value == prodlineID));
              if (productLineIndex !== -1) {
                propertiesToDelete.push(item.properties[productLineIndex]);
              }

              // ALSO Remove from product array
              if (productIndex !== -1) {
                const _deleteAnswers = new CustomArray<Answer>();
                // delete all answers from product using for next loop
                for (const product of item.product) {
                  for (const checklist of product.checklist) {
                    for (const question of checklist.question) {
                      if (question.answer && question.answer.length > 0) {
                        for (const answer of question.answer) {
                          try {
                            const json = JSON.parse(question.answer[0].properties.find((p) => p.type === AnswerPropertyType.Answer)?.value.toString() ?? '{}');
                            if (json.id === value.productId) {
                              _deleteAnswers.push(answer);
                            }
                          } catch {
                            console.log('could not parse answer\'s answer');
                          }
                        }
                      }
                    }
                  }
                }
                if (_deleteAnswers.length > 0) {
                  for (let i = 0; i < _deleteAnswers.length; i++) {
                    _deleteAnswers[i].created = -999;
                  }
                }
                item.product.splice(productIndex, 1);
              }

              // ProductLine - find the product with name = ProductLine, and whether answer's property answer's value = { id: productId }!!!
              let firstProductLineProduct = -1;
              try {
                firstProductLineProduct = item.product.findIndex(
                  (p) => p.properties.find(
                    (prop) => prop.type === ProductPropertyType.Name,
                  )?.value === 'ProductLine'
                    && !!p.checklist.find(
                      (c) => c.question.find(
                        (q) => q.answer?.find(
                          (a) => JSON.parse(a.properties.find((aprop) => aprop.type === AnswerPropertyType.Answer)?.value.toString() ?? '{}').id === value.productId,
                        ),
                      ),
                    ),
                );
              } catch {
                console.log('could not parse ProductLine\'s answer');
              }

              console.log(firstProductLineProduct);

              if (firstProductLineProduct !== -1) {
                // Get the answer from this item where the customproperty answer's value = { id: productId }
                for (const checklist of item.product[firstProductLineProduct].checklist) {
                  for (const question of checklist.question) {
                    if (question.answer) {
                      for (const answer of question.answer) {
                        try {
                          const json = JSON.parse(answer.properties.find((p) => p.type === AnswerPropertyType.Answer)?.value.toString() ?? '{}');
                          if (json.id === value.productId) {
                            // delete answer
                            const deleted = await this.deleteAnswer(answer);
                            if (deleted) {
                              // delete answer from question.answer
                              question.answer.splice(question.answer.findIndex((a) => a.id === answer.id), 1);
                            } else {
                              throw Error('Could not delete answer');
                            }
                          }
                        } catch {
                          console.log('could not parse answer\'s answer');
                        }
                      }
                    }
                  }
                  // item.product.splice(firstProductLineProduct, 1);
                }
              }
            }
            propertiesToDelete.push(property);
          }
        } catch (e) {
          const msg = `ChecklistUtils - removeOldProducts: ${(e as Error).message}`;
          logEntry(msg);
          console.log(msg);
          return false;
        }
      }
    }

    try {
      if (propertiesToDelete.length > 0) {
        for (let i = 0; i < propertiesToDelete.length; i++) {
          propertiesToDelete[i]._delete = true;
        }
        item.properties.assign(item.properties.filter((p) => !propertiesToDelete.some((d) => d.id === p.id)));
        await setData('Order', JSON.stringify(window.Data.order));
        utils.renderall();
        console.log('itemProperties product(questionref) deleted.');
      }
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  async removeOldProductsProductline(q: { dataset: Dataset; }, productId: number | undefined) {
    if (!q.dataset) {
      return;
    }

    const product = window.Data.product.find((p) => p.id === productId);
    if (!product) {
      return;
    }

    const item = window.Data.order.find((o) => o.id === Number(q.dataset.orderid))?.item.find((i) => i.id === Number(q.dataset.itemid));
    if (!item) {
      return;
    }

    const productline = item.product.find((p) => p.properties.find((prop) => prop.type === ProductPropertyType.Name)?.value === 'ProductLine');
    if (!productline) {
      return;
    }

    const propertiesToDelete: Property[] = [];

    const propertyIndex = item.properties.findIndex((p) => p.type === ItemPropertyType.Product
      && (p.value === product.id || p.value === product.id.toString()));
    if (propertyIndex === -1) {
      return;
    }

    if (propertyIndex - 1 >= 0) {
      propertiesToDelete.push(item.properties[propertyIndex]);
      // loop trough properties and find all ItemPropertyType.ProductQuestionRef matching productId
      for (let i = item.properties.length - 1; i >= 0; i--) {
        const property = item.properties[i];
        if (property.type === ItemPropertyType.ProductQuestionRef) {
          try {
            const value: {
              productId: string | number,
              questionId: string | number
            } = JSON.parse(property.value.toString());
            if (value.productId === product.id) {
              propertiesToDelete.push(property);
            }
          } catch (e) {
            console.log(`ChecklistUtils - removeOldProductsProductline: ${(e as Error).message}`);
          }
        }
      }
    }

    // remove one Productline from item.properties
    const propertyLineIndex = item.properties.findIndex((p) => p.type === ItemPropertyType.Product && (p.value === productline.id));
    if (propertyLineIndex !== -1) {
      propertiesToDelete.push(item.properties[propertyLineIndex]);
    }

    // delete productline from item.product where answer property contains productId
    const productLineIndex = item.product.findIndex((p) => p.id === productId);
    if (productLineIndex !== -1) {
      item.product.splice(productLineIndex, 1);
    }

    // ALSO Remove from product array
    if (productLineIndex !== -1) {
      const _deleteAnswers = new CustomArray<Answer>();
      // delete all answers from product using for next loop
      for (const product of item.product) {
        for (const checklist of product.checklist) {
          for (const question of checklist.question) {
            if (question.answer && question.answer.length > 0) {
              for (const answer of question.answer) {
                try {
                  const json = JSON.parse(question.answer[0].properties.find((p) => p.type === AnswerPropertyType.Answer)?.value.toString() ?? '{}');
                  if (json.id === productId) {
                    _deleteAnswers.push(answer);
                  }
                } catch {
                  console.log('could not parse answer\'s answer');
                }
              }
            }
          }
        }
      }
      if (_deleteAnswers.length > 0) {
        const response = await Backend('answers', HTTPMethods.DELETE, JSON.stringify(_deleteAnswers));
        if (!response.ok) {
          throw Error(response.statusText);
        }
      }
      item.product.splice(productLineIndex, 1);
    }
    /*
        for (let i = item.product.length - 1; i >= 0; i--) {
          const productLine = item.product[i];
          if (productLine.checklist) {
            for (const checklist of productLine.checklist) {
              if (checklist.question) {
                for (const question of checklist.question) {
                  if (question.answer) {
                    for (const answer of question.answer) {
                      // get answer
                      const answerProperty = answer.properties.find((p) => p.type === AnswerPropertyType.Answer);
                      // tyr JSON parse answer property
                      const answerValue = answerProperty ? JSON.parse(answerProperty.value.toString()) : null;
                      if (answerValue && answerValue.id === product.id) {
                        item.product.splice(productLineIndex, 1);
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        } */

    try {
      if (propertiesToDelete.length > 0) {
        const response = await Backend('itemProperties', HTTPMethods.DELETE, JSON.stringify(propertiesToDelete));
        if (!response.ok) {
          throw Error(response.statusText);
        }

        item.properties.assign(item.properties.filter((p) => !propertiesToDelete.some((d) => d.id === p.id)));
        await setData('Order', JSON.stringify(window.Data.order));
        utils.renderall();
        console.log('itemProperties product(questionref) deleted.');
      }
    } catch (error) {
      console.log(error);
    }
  }

  questionIsAnswered(question: Question | null): boolean {
    if (!question) {
      return false;
    }

    if (question._checklist?.includes('Menu')) {
      return false;
    }

    return !!question.answer?.length;
  }

  questionIsAnsweredOrNotRequired(question: Question | null): boolean {
    if (!question) {
      return false;
    }

    if (question._checklist?.includes('Menu')) {
      return false;
    }

    const required = this.required(question) as '1' | '0' | boolean;

    return !!question.answer?.length || !required;
  }

  async updateOnlyRemaining(element: HTMLDivElement, event: Event) {
    const checked = showOnlyHelper(element, event, 'Remaining');

    window.showOnlyRemaining = checked;

    await this.renderall();
  }

  async updateOnlyRequired(element: HTMLDivElement, event: Event) {
    const checked = showOnlyHelper(element, event, 'Required');

    window.showOnlyRequired = checked;

    await this.renderall();
  }

  addUpdateOrderProperty(order: Order, type: OrderPropertyType, value: Property['value'], update?: boolean) {
    // If not update, check exact
    // If update check that the type exists
    const existing = order.properties.find((p) => p.type === type && (update ? true : p.value === value));

    if (existing && update) {
      if (existing.id > 0 && existing.value !== value) {
        // Only set _updated on synced properties or where value was changed!
        existing._updated = true;
      }
      existing.value = value;
    } else if (!existing) {
      // add new property only if it does not exist
      order.properties.push({
        id: 0,
        parent_id: order.id,
        type,
        value,
      });
    }
  }

  addUpdateActiveOrderProperty(type: OrderPropertyType, value: Property['value'], update?: boolean) {
    this.addUpdateOrderProperty(window.Data.order[window.activeOrder], type, value, update);
  }

  async deleteOrderProperty(order: Order, type: OrderPropertyType, value: Property['value']) {
    const property = order.properties.find((p) => p.type === type && p.value === value);
    if (property) {
      const response = await Backend('orderProperties', HTTPMethods.DELETE, JSON.stringify([property]));
      if (response.ok) {
        order.properties.assign(order.properties.filter((p) => p.id !== property.id));
        await setData('Order', JSON.stringify(window.Data.order));
      }
    }
  }

  async deleteAnswers(item: Item, q: string | number) {
    const question = this.findQuestion(item, q);
    console.log(question);
    if (question) {
      if (!question.answer || !question.answer.length || question.answer.find((a) => a.id === 0)) {
        return;
      }

      const requestJson = question.answer.map((a) => ({
        id: a.id,
      }));

      const response = await Backend('answers', HTTPMethods.DELETE, JSON.stringify(requestJson));
      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      question.answer = new CustomArray<Answer>();
    }
  }

  async deleteAnswer(answer: Answer): Promise<boolean> {
    const response = await Backend('answers', HTTPMethods.DELETE, JSON.stringify([answer]));
    return response.ok;
  }

  setQuestionReadonly(question: Question | null, value: boolean) {
    const properties = question?.properties.filter((p) => p.type === QuestionPropertyType.WriteProtected);
    if (properties && properties.length > 0) {
      for (let i = 0; i < properties.length; i++) {
        properties[i].value = value.toString();
      }
    } else {
      question?.properties.push({
        id: 1,
        parent_id: question.id,
        type: QuestionPropertyType.WriteProtected,
        value: value.toString(),
      });
    }
  }

  setOrderReadonly(order: Order) {
    // loop through order and set all questions to readonly
    for (const item of order.item) {
      for (const product of item.product) {
        for (const checklist of product.checklist) {
          for (const question of checklist.question) {
            this.setQuestionReadonly(question, true);
          }
        }
      }
    }
  }

  toggleDebugInfo(value: boolean) {
    setShowDebugInfo(value);
  }

  async testDuplicateExtraAnswer(order: Order) {
    const itemSalg = order.item.find((i) => i.product.find((p) => p.checklist.find((c) => c.properties.find((pp) => pp.type === ChecklistPropertyType.Name)?.value === 'Salg_Akunde')));

    if (!itemSalg) return;

    const filename = 'https://github.com/ACsenteret/activityhelper_node';
    const filename2 = 'https://github.com/ACsenteret/cppro';
    // Update order with PDF link
    const quoteQuestionid = 1487;

    const existingPropery = itemSalg.properties.find((p) => p.type === ItemPropertyType.Question && p.value === quoteQuestionid);

    if (!existingPropery) {
      const newItemProperty: Property[] = [{
        id: 0,
        parent_id: itemSalg.id,
        type: ItemPropertyType.Question,
        value: quoteQuestionid,
      }];
      const itemsResponse = await Backend('itemProperties', HTTPMethods.POST, JSON.stringify(newItemProperty));
      if (!itemsResponse.ok) {
        console.error(`${order.id}: Failed to add item to order`);
        throw Error(itemsResponse.statusText);
      }
    }

    // const [item] = await itemsResponse.json() as Item[];

    const link = { NOR: 'Test link 1', Link: filename };
    const link2 = { NOR: 'Test link 2', Link: filename2 };
    const custQuestionProperty = [
      { id: 0, type: 1, value: 'Quote' },
      { id: 0, type: 3, value: '1500' },
      { id: 0, type: 4, value: '100000' },
      { id: 0, type: 8, value: '7' },
      { id: 0, type: QuestionPropertyType.Data, value: JSON.stringify(link) },
      { id: 0, type: QuestionPropertyType.SpecialHandling, value: 'Copy to order' },
    ];

    const custQuestionProperty2 = [
      { id: 0, type: 1, value: 'Quote' },
      { id: 0, type: 3, value: '1500' },
      { id: 0, type: 4, value: '100001' },
      { id: 0, type: 8, value: '7' },
      { id: 0, type: QuestionPropertyType.Data, value: JSON.stringify(link2) },
      { id: 0, type: QuestionPropertyType.SpecialHandling, value: 'Copy to order' },
    ];

    const answers: Answer[] = [
      {
        id: 0,
        created: 0,
        parent_id: quoteQuestionid,
        item_id: itemSalg.id,
        checklist_id: 1, // q1487's checklist id
        seq_no: 100000,
        properties: new CustomArray<Property>(...[
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.Answer,
            value: filename,
          },
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.CustomQuestionProperties,
            value: JSON.stringify(custQuestionProperty),
          },
        ]),
      },
      {
        id: 0,
        created: 0,
        parent_id: quoteQuestionid,
        item_id: itemSalg.id,
        checklist_id: 1, // q1487's checklist id
        seq_no: 100001,
        properties: new CustomArray<Property>(...[
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.Answer,
            value: filename2,
          },
          {
            id: 0,
            parent_id: 0,
            type: AnswerPropertyType.CustomQuestionProperties,
            value: JSON.stringify(custQuestionProperty2),
          },
        ]),
      },
    ];
    const answersResponse = await Backend('answers', HTTPMethods.POST, JSON.stringify(answers));
    if (!answersResponse.ok) {
      console.error(`${order.id}: Failed to add quote answer to order`);
      throw Error(answersResponse.statusText);
    }
  }

  async copyAnswer(question: Question, questionIdToCopy: number | string) {
    const answer = this.getAnswerObject(questionIdToCopy);
    if (answer) {
      const value = answer.properties.find((p) => p.type === AnswerPropertyType.Answer)?.value ?? '';
      this.updateAnswer({
        dataset: {
          checklist: question._checklist ?? '',
          checklistid: question._checklistid?.toString() ?? '',
          itemid: question._itemid?.toString() ?? '',
          orderid: question._order_id?.toString() ?? '',
          seqno: question._seqno.toString(),
          productid: question._product?.toString() ?? '',
          questionid: question.id.toString(),
        },
      }, value);
      // const copy = structuredClone(answer);
      // copy.id = 0;
      // copy.parent_id = question.id;
      // copy.checklist_id = question._checklistid as number ?? question.parent_id as number;
      // copy.item_id = question._itemid as number;
      // copy.seq_no = question._seqno as number;
      // for (const prop of copy.properties ?? []) {
      //   prop.id = 0;
      //   prop.parent_id = 0;
      // }
      // question.answer = new CustomArray<Answer>(copy);
    }
  }

  async setCurrentUserAsAnswer(question: Question) {
    if (question.answer?.length) return;

    const user = window.activeUser;
    if (!user) return;

    const created = Math.floor(new Date().getTime() / 1000);

    question.answer = new CustomArray<Answer>();
    const properties = new CustomArray<Property>();
    properties.push(
      {
        id: 0,
        parent_id: 0,
        type: AnswerPropertyType.Answer,
        value: user.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString() ?? '',
      },
    );
    if (!question._itemid || !question._checklistid) return;
    question.answer?.push({
      id: 0,
      created,
      parent_id: question.id,
      item_id: question._itemid,
      checklist_id: question._checklistid,
      seq_no: question._seqno,
      properties,
    });

    await this.syncData();
  }

  async stemplingSetUser(question: Question) {
    const user = window.activeUser;
    if (!user) return;

    const email = user.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString();
    if (email === 'checkpoint.server@acsenteret.no' || !email) return;

    if (!question.answer?.length) {
      this.addUpdateActiveOrderProperty(OrderPropertyType.StemplingName, email, true);
    }

    await this.setCurrentUserAsAnswer(question);
  }

  stemplingInsertUsers(question: Question): void {
    const { users } = window.Data;

    if (!users?.length) {
      return;
    }

    const currentUserEmail = window.activeUser?.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString().trim();
    const isCheckpointServer = currentUserEmail === 'checkpoint.server@acsenteret.no';

    const sortedUsers = users
      .map((user) => user.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString().trim())
      .filter(removeNullsAndUndefined)
      .filter((email) => (isCheckpointServer ? email.endsWith('@acsenteret.no') || email.endsWith('@fjklima.no') : true));

    sortedUsers.sort((a, b) => a.localeCompare(b));

    const properties: Property[] = sortedUsers.map((user) => ({
      id: 1,
      type: QuestionPropertyType.Data,
      value: `{"Item":{"NOR":"${user}", "data":"${user}"}}`,
      parent_id: question.id,
    }));

    const questionProperties = question.properties.filter((p) => p.type !== QuestionPropertyType.Data);

    question.properties = [...questionProperties, ...properties];
  }

  /**
   *
   * @param answer
   * @param isTo
   * @param otherQ
   * @param maxDays Days to add to get maximum date
   * @param minDays Days to subtract to get minimum date (do NOT use negative numbers)
   */
  fromToValidation(answer: string | number | undefined, isTo?: boolean, otherQ?: string | number, maxDays?: number, minDays?: number) {
    if (!answer) {
      return true;
    }
    const isNOR = window.activelanguage === 'NOR';

    let otherAnswer: string | number | undefined = undefined;
    if (otherQ) otherAnswer = this.getAnswer(otherQ);
    const answerDate = new Date(answer);
    if (Number.isNaN(answerDate.getTime())) {
      this.showWarningToast(isNOR ? 'Dato er ugyldig' : 'From date is invalid', 6000);
      return false;
    }

    // Get minimum date, default (if !minDays) = 1. in last month
    const minDate = minDays ? DateUtils.subtractDateDays(undefined, minDays) : DateUtils.getDateFirstDayInLastMonth();
    if (minDate > answerDate) {
      this.showWarningToast(isNOR ? 'Dato er for gammel' : 'From date is too old', 6000);
      return false;
    }

    // Get maximum date, default (if !maxDays) = +60 days from now
    const maxDate = maxDays ? DateUtils.subtractDateDays(undefined, -maxDays) : DateUtils.subtractDateDays(undefined, -60);
    if (maxDate < answerDate) {
      this.showWarningToast(isNOR ? 'Dato er for ny' : 'From date is too new', 6000);
      return false;
    }

    if (otherAnswer) {
      const otherAnswerDate = new Date(otherAnswer);
      if (Number.isNaN(otherAnswerDate.getTime())) return true;
      if (isTo) {
        if (otherAnswerDate > answerDate) {
          this.showWarningToast(isNOR ? 'Til dato må vere etter før dato' : 'To date must be after from date', 6000);
          return false;
        }
      } else if (otherAnswerDate < answerDate) {
        this.showWarningToast(isNOR ? 'Fra dato må vere før til dato' : 'From date must be before to date', 6000);
        return false;
      }
    }

    return true;
  }

  /**
   * Example on how to use it (in OnAnswer property):
   *
   * ```
   * if (!utils.dateValidation(answer, new Date(2023,0,1), null, [new Date(2070,0,1)])) answer = undefined;
   * ```
   * This checks if date is >= 2023-01-01 with no upper limit. Date 2070-01-01 is also allowed
   * @param answer
   * @param min Defaults to 1. in last month. Answer date needs to be larger or equal. Null disables the check.
   * @param max Defaults to +60 days from now. Answer date needs to be smaller or equal. Null disables the check.
   */
  dateValidation(answer: string | number | undefined, min?: Date | null, max?: Date | null, allowedDates?: (Date | string)[]) {
    if (!answer) {
      return true;
    }
    const isNOR = window.activelanguage === 'NOR';

    const answerDate = new Date(answer);
    if (Number.isNaN(answerDate.getTime())) {
      this.showWarningToast(isNOR ? 'Dato er ugyldig' : 'From date is invalid', 6000);
      return false;
    }

    if (allowedDates?.length) {
      if (allowedDates.some((date) => DateUtils.compareDates(new Date(date), answerDate))) {
        return true;
      }
    }

    // Get minimum date, default (if !minDays) = 1. in last month
    if (min !== null) {
      const minDate = min ?? DateUtils.getDateFirstDayInLastMonth();
      if (minDate >= answerDate) {
        this.showWarningToast(isNOR ? 'Dato er for gammel' : 'From date is too old', 6000);
        return false;
      }
    }

    if (max !== null) {
      // Get maximum date, default (if !maxDays) = +60 days from now
      const maxDate = max ?? DateUtils.subtractDateDays(undefined, -60);
      if (maxDate <= answerDate) {
        this.showWarningToast(isNOR ? 'Dato er for ny' : 'From date is too new', 6000);
        return false;
      }
    }

    return true;
  }

  updateQuestionText(question: Question, textNOR: string, textENG?: string) {
    const nor = question.properties.find((p) => p.type === QuestionPropertyType.NOR);
    const eng = question.properties.find((p) => p.type === QuestionPropertyType.ENG);

    if (nor) {
      nor.value = textNOR;
    }

    if (eng && textENG) {
      eng.value = textENG;
    }
  }

  /**
   * Example on how to use it (in OnAnswer property):
   *
   * ```
   * if (!utils.textLengthValidation(answer, 4, 4, /\d{4}/, 'Tekst må vere 4-sifra postnummer', 'Text must be 4-digit zip code')) answer = undefined;
   * ```
   * This checks if answer is exactly 4 in length and has 4 digits
   * @param answer
   * @param min Required
   * @param max Optional
   * @param regex Optional
   * @param errorTextNOR Optional
   * @param errorTextENG Optional
   */
  textLengthValidation(answer: string | number | undefined, min: number, max?: number, regex?: RegExp, errorTextNOR?: string, errorTextENG?: string) {
    if (answer === undefined) return true;

    let textSchema = z.string().min(min);
    if (max) textSchema = textSchema.max(max);
    if (regex) textSchema = textSchema.regex(regex);

    const parsed = textSchema.safeParse(answer);
    if (parsed.success) return true;

    // const error = parsed.error.message;

    const isNOR = window.activelanguage === 'NOR';
    this.showWarningToast(`${isNOR ? errorTextNOR ?? 'Ugyldig tekstlengde' : errorTextENG ?? 'Invalid text length'}`, 6000);
    return false;
  }

  async open24SOOrder(answer: string | number | undefined, client: string, demo?: boolean) {
    if (!answer) return;
    const isDemo = demo ?? import.meta.env.MODE === 'test';

    const response = await ActivityHelper<Order>(`api/cppro/24so/${answer}/createOrder?client=${encodeURIComponent(client)}&demo=${isDemo.toString()}`, HTTPMethods.POST);

    if (response instanceof AxiosError) {
      const isNOR = window.activelanguage === 'NOR';
      this.showErrorToast(`${isNOR ? 'Error opening 24SO order: ' : 'Feil ved åpning av 24SO ordre: '}${response.message ?? 'Error'}`, 6000);
    } else {
      await this.openOrder(undefined, undefined, response.id.toString());
    }
  }

  async changeDeliveryDate(answer: string | number | undefined, cpproInstallId?: number) {
    if (!answer) return;
    const data = {
      cpproInstallId: cpproInstallId || window.Data.order[window.activeOrder].id,
      deliveryDate: answer,
    };
    const response = await ActivityHelper<string>('api/erp/ac/calendar/deliveryDateChange', HTTPMethods.POST, data);
    if (response instanceof AxiosError) {
      const isNOR = window.activelanguage === 'NOR';
      this.showErrorToast(`${isNOR ? 'Feil ved endring av leveringsdato: ' : 'Error changing delivery date: '}${response.response?.data ?? response.message ?? 'Error'}`, 6000);
    } else {
      this.showInfoToast(response, 6000);
    }
  }

  async checkEnTurTilAnswer(params: { answer: string | number | undefined }): Promise<string | number | undefined> {
    if (params.answer?.toString() === '0') return params.answer;
    const orderId = window.Data.order[window.activeOrder].id;

    const response = await ActivityHelper<{ valid: boolean, message: string, skipError?: boolean }>(`api/erp/order/${orderId}/checkEnTurTilOrderInvoiced`, HTTPMethods.GET);
    const isNOR = window.activelanguage === 'NOR';
    if (response instanceof AxiosError) {
      this.showErrorToast(`${isNOR ? 'Feil ved sjekk av EN-TUR-TIL: ' : 'Error checking EN-TUR-TIL: '}${response.message ?? 'Error'}`, 6000);
    } else if (!response.valid && !response.skipError) {
      params.answer = '0';
      const norText = '"En Tur Til" spørsmål låst pga tidsfrist, sak blir ikke videresendt support. Kunde må legge inn sak i portalen';
      const engText = '"One more trip" question locked because of timelimit, case is not sent to support. Customer needs to add a case in supportportal';
      this.alertHandler(isNOR ? norText : engText);
      this.updateQuestionAnswer('148', '0');
      return '0';
    }
    return params.answer;
  }

  async callActivityHelper<T>(endpoint: string, method: HTTPMethods, body?: unknown, override?: boolean) {
    const response = await ActivityHelper<T>(endpoint, method, body, override);
    return response;
  }

  async callBackend(endpoint: string, method: HTTPMethods, body?: string, form?: FormData, silent?: boolean, text?: boolean) {
    const response = await Backend(endpoint, method, body, form, silent);
    const data = text ? await response.text() : await response.json();
    return data;
  }

  async alertHandler<T>(alertText: string, handler?: () => Promise<T>) {
    window.alert(alertText);
    if (handler) await handler();
  }

  async confirmHandler<TConfirm, TAbort>(confirmText: string, onConfirm: () => Promise<TConfirm>, onAbort?: () => Promise<TAbort>) {
    // TODO Use dialog?
    if (window.confirm(confirmText)) {
      await onConfirm();
    } else if (onAbort) {
      await onAbort();
    }
  }
}

function showOnlyHelper(element: HTMLDivElement, event: Event, type: 'Required' | 'Remaining'): boolean {
  event.preventDefault();
  event.stopPropagation();
  let checked = false;
  for (let i = 0; i < element.children.length; i++) {
    const child = element.children[i];
    if (child.tagName === 'INPUT') {
      checked = !(child as HTMLInputElement).checked;
      break;
    }
  }

  // TODO There might be a better "css" way to do this?
  const inputTop = document.getElementById(`showOnly${type}Top`) as HTMLInputElement | null;
  const inputBottom = document.getElementById(`showOnly${type}Bottom`) as HTMLInputElement | null;

  if (inputTop && inputTop.parentElement) {
    if (checked) {
      inputTop.parentElement.className = 'filter-checkbox filter-checkbox-checked';
    } else {
      inputTop.parentElement.className = 'filter-checkbox';
    }
    inputTop.checked = checked;
  }

  if (inputBottom && inputBottom.parentElement) {
    if (checked && inputBottom.parentElement) {
      inputBottom.parentElement.className = 'filter-checkbox filter-checkbox-checked';
    } else {
      inputBottom.parentElement.className = 'filter-checkbox';
    }
    inputBottom.checked = checked;
  }

  return checked;
}

const utils = new ChecklistUtils();

const loadImage = (file: File) => new Promise<string>((resolve, reject) => {
  const fr = new FileReader();
  fr.onload = async (fileEvent) => {
    const dataUrl = <string>fileEvent?.target?.result;
    const resizedImage = await utils.resizeImage(dataUrl);
    resolve(resizedImage);
  };
  fr.onerror = (e) => {
    reject(e);
  };
  if (file) {
    // read file as data url after 2 seconds, to allow camera app to close
    // setTimeout(() => {
    fr.readAsDataURL(file);
    //      }, 500);
  } else {
    reject(new Error('No file was selected'));
  }
});

export default ChecklistUtils;
